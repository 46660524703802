import firebase from "firebase/app";
import "firebase/storage";
import 'firebase/auth';
import { APP_NAME, firebaseConfig } from '../constants';

let storage;
export const firebaseResendTime = 30; // in seconds

export function signInWithCustomToken(token) {
    return new Promise((resolve, reject) => {
        //  console.log("aa -------- token=>", token);
        if (!token) {
            reject("Invalid Token");
            return;
        }
        firebase
        .auth()
        .signInWithCustomToken(token)
        .then(newUser => {
            //  console.log("aa -------- newUser=>", newUser);
            resolve(newUser);
        }).catch(error => {
            // console.log("signIn error code=>", error?.code);
            // console.log("signIn error message=>", error?.message);
            reject(error);
        });
    });
}

/**
 * Function use to get current login firebase user in app
 */
export function getFirebaseUser() {
    return firebase?.auth()?.currentUser;
}

export async function signOut() {
    return firebase?.auth()?.signOut();
}

export const initializeApp = (token) => {
  if (firebase.apps.length > 0) {
    storage = firebase.storage();
  } else {
    // use environment-specific firebase config
    let firebaseApp = firebase.initializeApp(firebaseConfig);
    storage = firebase.storage();
  }
  signInWithCustomToken(token);
};

/**
 * getMediaUrl
 */
 export const getMediaUrl = (path) => {
  return new Promise((resolve, reject) => {
    storage
      .ref(`${path}`)
      .getDownloadURL()
      .then((url) => {
        resolve(url);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

  export default firebase;