import _ from 'lodash';
import React, { Component } from 'react';
import LocalizedStrings from 'react-localization';
import { connect } from "react-redux";
import { Col, Media, Row ,Navbar} from 'reactstrap';
import EmptyCardsIcon from '../../assets/card_icon.png';
import DeleteImg from '../../assets/delete.png';
//card icons
import AmericanExpressIcon from '../../assets/images/cards/ic_american_express.svg';
import BankOfAmericaIcon from '../../assets/images/cards/ic_bank_of_america.svg';
import BarclaysIcon from '../../assets/images/cards/ic_barclays.svg';
import CapitalOneBankIcon from '../../assets/images/cards/ic_capital_one_bank.svg';
import ChaseIcon from '../../assets/images/cards/ic_chase.svg';
import CitiBankIcon from '../../assets/images/cards/ic_citi_bank.svg';
import DefaultCardIcon from '../../assets/images/cards/ic_default_card.svg';
import DinersClubIcon from '../../assets/images/cards/ic_diner_club.svg';
import DiscoverIcon from '../../assets/images/cards/ic_discover.svg';
import JcbIcon from '../../assets/images/cards/ic_jcb.svg';
import MasterCardIcon from '../../assets/images/cards/ic_master_card.svg';
import PncIcon from '../../assets/images/cards/ic_pnc.svg';
import UsaaIcon from '../../assets/images/cards/ic_usaa.svg';
import UsBankIcon from '../../assets/images/cards/ic_us_bank.svg';
import VisaIcon from '../../assets/images/cards/ic_visa.svg';
import WellsFargoIcon from '../../assets/images/cards/ic_wells_fargo.svg';
import PlusIcon from '../../assets/plus_icon.png';
import { deleteBankDetailsAPI, getAllCardListAPI, getBankDetailsAPI, removeSavedCardAPI, setDefaultCardAPI } from '../../utils/api-helper';
import { confirmAlert, showSpinner, showToast } from '../../utils/common_helper';
import { get } from '../../utils/local-storage-helper';
import { data } from '../../utils/locales/data';
import { initializeStripe } from '../../utils/stripe-helper';
import EmptyAction from '../empty-action';
import AddBankDetails from './add_bank_details';
import AddCard from './add_card';
import './style.scss';
import MediaQuery from 'react-responsive'
import { FaAngleLeft, FaBars, FaPlus } from 'react-icons/fa';

let strings = new LocalizedStrings(data);
class Payment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bankDetails: null,
            cardList: [],
            selectedCard: null,
            isAddCard: false,
            isCardView: false,
            isBankDetailsView: false,
            isAddBankDetails: false,
            isFirstTimeLoaded: false,
            showConfirmAlert: false,
            deleteCardId: '',
            isDataFatched: false,
            isLoading: false,
            showLoader: false
        }
    }

    componentDidMount = () => {

        initializeStripe();
        this.loadData();
        // setInterval(() => {
        //     this.loadData();
        //     this.setState({isLoading:false})
        // }, 3000)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.authData !== this.props.authData) {
            if (this.state.isFirstTimeLoaded === false) {
                if (this.state.role === 'GENERAL') {
                    this.getAllCardsList();
                }
            }
        }
    }

    handleChange = (event) => {
        this.setState({ selectedCard: event.target.value });
    };

    onCloseModal = () => {
        console.log("===>data")
        this.setState({ isAddCard: false });
        this.getAllCardsList();
    }

    onAddCard = () => {
        this.setState({ isAddCard: true });

    }

    loadData = () => {
        // let role = get("LUXECREWS_USER_ROLE");
        // if (role != null) {
        //     this.state.role = role;
        // if (this.state.role === 'GENERAL') {
        this.getAllCardsList();
        this.setState({ isAddCard: false, isAddBankDetails: false, isCardView: true });
        // } else {
        //     this.setState({ isAddCard: false, isAddBankDetails: true, isBankDetailsView: true });
        //     this.getBankDetails();
        // }
        // }

    }

    /**
     * Function to get card list from backend server
     */
    getAllCardsList = () => {
        this.setState({ isLoading: true });
        getAllCardListAPI().then(response => {
            this.setState({ isLoading: false, isFetchingCards: false, isFirstTimeLoaded: true, isDataFatched: true });
            var updatedCardList = response.data ? response.data : [];
            this.setState({ cardList: updatedCardList })
        }).catch(this.handleError);
    }

    handleError = (error) => {
        this.setState({ showLoader: false, isLoading: false, isDataFatched: true }, () => {
            if (error?.message) {
                showToast(error?.message, "error");
            }
        });
    }

    callDeleteCardAPI = (id) => {
        let body = {
            "cardId": id
        }
        this.setState({ showLoader: true });
        removeSavedCardAPI(body).then(response => {
            this.state.showLoader = false;
            let deletedItemList = this.state.cardList.splice(this.state.deleteCardIndex, 1);
            if (deletedItemList?.length && this.state.cardList?.length && deletedItemList[0].isDefault) {
                this.setAsPrimaryCard(this.state.cardList[0], 0);
            }
            this.setState({ deleteCardId: null, deleteCardIndex: null });
        }).catch(this.handleError);
    }

    callDeleteBankDetailsAPI = () => {
        this.setState({ showLoader: true, isLoading: true });
        deleteBankDetailsAPI().then(response => {
            if (response) {
                this.setState({ showLoader: false, isLoading: false, bankDetails: null });
                showToast(response?.message, "success");
            }
        }).catch(error => this.handleError(error.data));
    }

    /** 
     * Function to set card as primary/default card
     */
    setAsPrimaryCard = (item, index) => {
        let body = {
            "cardId": item.cardId
        }
        var updatedCardList = this.state.cardList;
        let newList = updatedCardList.map((item, idx) => {
            item.isDefault = index === idx
            return item;
        })
        this.setState({ cardList: newList })
        setDefaultCardAPI(body).then(response => {
        }).catch(error => { console.log("aa ------ setDefaultCardAPI error=>", error) });
    }

    /**
     * Function to get bank details from backend server
     */
    getBankDetails = () => {
        this.setState({ isLoading: true });
        getBankDetailsAPI().then(response => {
            this.setState({ isLoading: false, isDataFatched: true });
            if (response && response.code === 200) {
                if (response.data && !_.isEmpty(response.data)) {
                    this.setState({ isLoading: false, bankDetails: response.data });
                }
            }
        }).catch(this.handleError);
    }

    onConfirmDelete = () => {
        this.callDeleteCardAPI(this.state.deleteCardId)
    }

    onCancelDelete = () => {
        this.setState({ showConfirmAlert: false });
    }

    deleteCard = (item, index) => {
        this.setState({ showConfirmAlert: true, deleteCardId: item?.cardId, deleteCardIndex: index, confirmAlertMessage: strings.confirm_delete_card });
        // this.callDeleteCardAPI(item.cardId)
    }

    onDeleteBank = () => {
        this.setState({ showConfirmAlert: true, confirmAlertMessage: strings.confirm_delete_bank });
    }

    onAddBank = () => {
        this.getBankDetails()
    }

    getBrandImage = (brandType) => {
        if (brandType) {
            if (brandType.toLowerCase().search('visa') > -1) {
                return VisaIcon;
            } else if (brandType.toLowerCase().search('mastercard') > -1) {
                return MasterCardIcon
            } else if (brandType.toLowerCase().search('american express') > -1) {
                return AmericanExpressIcon;
            } else if (brandType.toLowerCase().search('discover') > -1) {
                return DiscoverIcon;
            } else if (brandType.toLowerCase().search('diners club') > -1) {
                return DinersClubIcon;
            } else if (brandType.toLowerCase().search('jcb') > -1) {
                return JcbIcon;
            } else if (brandType.toLowerCase().search('barclays') > -1) {
                return BarclaysIcon;
            } else if (brandType.toLowerCase().search('chase') > -1) {
                return ChaseIcon;
            } else if (brandType.toLowerCase().search('bank of america') > -1) {
                return BankOfAmericaIcon
            } else if (brandType.toLowerCase().search('usaa') > -1) {
                return UsaaIcon;
            } else if (brandType.toLowerCase().search('usbank') > -1) {
                return UsBankIcon
            } else if (brandType.toLowerCase().search('pnc') > -1) {
                return PncIcon;
            } else if (brandType.toLowerCase().search('capital one bank') > -1) {
                return CapitalOneBankIcon
            } else if (brandType.toLowerCase().search('citi bank') > -1) {
                return CitiBankIcon
            } else if (brandType.toLowerCase().search('wells fargo') > -1) {
                return WellsFargoIcon;
            } else {
                return DefaultCardIcon;
            }
        }
    }

    mobileclick(){
        this.setState({isAddCard:true})
    }
    render() {
        const { cardList, isCardView, isBankDetailsView, isDataFatched } = this.state;
        console.log("data000", this.props)
        return (
            <>
            
                {
                    confirmAlert(strings.confirm_delete_card,
                        'Yes',
                        'Cancel',
                        this.state.showConfirmAlert,
                        '',
                        () => { this.onConfirmDelete() },
                        () => { this.onCancelDelete() })
                }
                {showSpinner(this.state.isLoading)}
                {!isBankDetailsView ?
                    <>
                        <div className="common-page-wrapper">
                            <Row md={2} className="common-page-header">
                                <Col className="title">{strings.add_card}</Col>
                                <Col className="header-icons">
                                    {isCardView ?
                                        <>
                                            {cardList ?
                                                <>

                                                    <img src={PlusIcon} onClick={this.onAddCard} />
                                                    <span onClick={this.onAddCard}>{strings.add}</span>
                                                </>
                                                :
                                                // <Button className="common-button common-delete-button">{strings.delete}</Button>
                                                null
                                            }
                                        </>
                                        : null
                                    }
                                </Col>
                            </Row>
                            <div className="page-content">
                                <MediaQuery maxWidth={480}>
                                    {cardList.length > 0 &&
                                        <div className='addButton' onClick={() => this.mobileclick()}>
                                            <FaPlus onClick={() => this.onAddCard()} color="#a58b89" size={15} style={{ marginRight: 5 }} />
                                            <span onClick={() => this.onAddCard()} style={{ fontSize: 15,color:"#a58b89" }}>Add</span>
                                        </div>
                                    }
                                </MediaQuery>
                                {isCardView ?
                                    <div className="card-list">
                                        {cardList && cardList.length > 0 ?
                                            <>
                                                {cardList.map((card, index) => {
                                                    return (

                                                        <div className="card-item" key={`cardList001${index}`}>

                                                            <>
                                                                <input type="radio" id="radio1" className="radio-control" value={card.cardId} onChange={() => { this.setAsPrimaryCard(card, index) }} checked={card.isDefault} />
                                                                <span></span>
                                                                <label for="radio1" className="radio-label">{strings.set_as_primary}</label>
                                                            </>
                                                            <div className="card-content">
                                                                <img src={this.getBrandImage(card.brand)} />
                                                                <div className="card-info">
                                                                    <div>
                                                                        <p className="card-no">{`XXXX XXXX XXXX ${card.last4}`}</p>
                                                                        <p className="card-expiry-date">{strings.expires_labels} {`${card.expMonth}/${card.expYear}`}</p>
                                                                    </div>
                                                                    <div className='edit-icon' onClick={() => this.deleteCard(card, index)}>
                                                                        <img src={DeleteImg} />

                                                                    </div>

                                                                </div>

                                                            </div>
                                                        </div>
                                                    );
                                                })
                                                }
                                            </>
                                            : isDataFatched
                                                ?
                                                <div className='NoCards'>
                                                    <EmptyAction icon={EmptyCardsIcon} message={strings.no_card_info_added} button={true} buttonText={strings.add_new_card} buttonClick={this.onAddCard} />
                                                </div>
                                                : null
                                        }
                                    </div>
                                    : null
                                }
                            </div>
                        </div>
                        <AddCard modalStatus={this.state.isAddCard} onClose={this.onCloseModal}></AddCard>
                    </>
                    : <AddBankDetails
                        bankDetails={this.state.bankDetails}
                        isAddBankDetails={this.state.isAddBankDetails}
                        onDeleteBank={() => this.callDeleteBankDetailsAPI()}
                        onAddBank={() => this.onAddBank()} />
                }
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    authData: state.auth.authData,
});
export default connect(mapStateToProps, {})(Payment);