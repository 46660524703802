
/**
 * Function to initialize stripe in application.
 * (Please invoke this function before use of below functions)
 */
export function initializeStripe() {
    if (!window.document.getElementById('stripe-script')) {
        var s = window.document.createElement("script");
        s.id = "stripe-script";
        s.type = "text/javascript";
        s.src = "https://js.stripe.com/v2/";
        s.onload = () => {
            window['Stripe'].setPublishableKey('pk_live_51J20gkBmgGWhlN8XRWUybJ36ogbl5IYOMOwiAX5w9jueBpp9CNjedBWdDvnhRue462zWUL9m8hQgGj7pMKeeKnb400sAJgtNyj');
            // window['Stripe'].setPublishableKey('pk_test_51J20gkBmgGWhlN8XBOa8sXB88su4Xo9gqd2pXWqQZ3Vh8rgK2vkT9ds4fc8gYdqmfE92yETcF54QFNXXoX0rZgBg00vux9apnC');

        }
        window.document.body.appendChild(s);
    }
}

/**
 * Function to get card token from stripe.
 * @param {*} payload payload data.
 * @param {*} callBack callback function which contains status & response.
 */
export function saveCardDetails(payload, callBack) {
    //Refer below payload for this function.
    // let payload = {
    //     number: '4242424242424242',
    //     exp_month: '01',
    //     exp_year: '2023',
    //     cvc: '123',
    //     name: 'Jenny Rosen'
    // }
    window.Stripe.card.createToken(payload, (status, response) => {
        if (status === 200) {
          // console.log('Success of card in helper ==> ', response, status);
          callBack(status, response);
   
        } else {
          // console.log('Error of card in helper ==> ', response, status);
          callBack(status, response);
        }
    });
}

/**
 * Function to get bank account token from stripe
 * @param {*} payload payload data
 * @param {*} callBack callback function which contains status & response.
 */
export function saveBankDetails(payload, callBack) {
    //Refer below payload for this function.

    // let bankAccountPayload = {
    //     country: 'US',
    //     currency: 'usd',
    //     account_holder_type: 'individual',
    //     routing_number: '110000000',
    //     account_number: '000123456789'
    // }
    
    window.Stripe.bankAccount.createToken( payload, (status, response) => {
        if (status === 200) {
          // console.log('Success of bank in helper ==> ', response, status);
          callBack(status, response);
   
        } else {
          // console.log('Error of bank in helper ==> ', response, status);
          callBack(status, response);
        }
    });
}