import React from 'react';
import ResetPasswordComponent from '../components/user_registration/reset_password';

const ResetPassword = props => {
    return (
        <div>
            <ResetPasswordComponent />
        </div>
    );
}
export default ResetPassword;