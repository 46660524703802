import React, { Component } from 'react';
import { data } from '../../../utils/locales/data';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import {Row, Col, Button} from 'reactstrap';
import TextField from '@material-ui/core/TextField';
import '../auth.scss';
import EmailIcon from '../../../assets/email.png';
import PasswordIcon from '../../../assets/password.png';
import { validateEmail } from '../../../utils/input-validation';
import { sendOTPAPI } from '../../../utils/api-helper';
import { showToast, showSpinner } from '../../../utils/common_helper';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router'

let strings = new LocalizedStrings(data);
class ForgotPassword extends Component {
    constructor(props) {
        super(props)

        this.state = {
            email: '',
            errors: {},
            isSubmitted: false
        }
    }
    
    validate() {
	    let errors = {};
        let IsValid = true;
      
        if (!validateEmail(this.state.email.trim())) {
            IsValid = false;
            errors["email"] = strings.error_email;
        }
       
        this.setState({ errors: errors });
        return IsValid;
	}
    
    handleSubmit = (e) => {
        this.setState({isSubmitted: true});
        e.preventDefault();
        if (this.validate()) {
            this.setState({ showLoader: true });
            let body = {
                emailId: this.state.email,
                otpType: "FORGOT_PASSWORD",
                requestFrom: 'WEB_APP'
            }
            sendOTPAPI(body).then(response => {
                this.setState({ showLoader: false, isRedirect: true });
            }).catch(error => {
                this.setState({ showLoader: false });
                if (error?.data?.message) {
                    showToast(error?.data?.message, "error");
                  }
            });
        }
    };

    handleChange = (e) => {
	    let errors = {};
        let IsValid = true;
        this.setState({ [e.target.name]: e.target.value });
        if(this.state.isSubmitted){
            if (e.target.name == "email"){
                if (!validateEmail(e.target.value.trim())) {
                    IsValid = false;
                    errors["email"] = strings.error_email;
                }
            }
            this.setState({ errors: errors });
            return IsValid;
        }   
    }

    render() {
        return (
            <>
            <div className="common-auth-wrapper">
                {showSpinner(this.state.showLoader)}
               <Row>
                    <Col className="bg-image" >
                        <div className="logo"></div>
                    </Col>
                    <Col className="common-reg-wrapper">
                        <div className="form-wrapper">
                            {strings.getLanguage() ?
                                    <>
                                        <p className="title" >{strings.forgot_password_label}</p>
                                        <p className="subtext">{strings.forgot_password_text}</p>
                                    </>
                                : <></>
                            }
                            <form autoComplete="off" onSubmit={this.handleSubmit}>
                                <div className="text-item email-field">
                                    <div className="text-icon">
                                        <img src={EmailIcon} />
                                    </div>
                                    <TextField className="text-field" onChange={this.handleChange}
                                        error={this.state.errors.email}
                                        id="email"
                                        name="email"
                                        label={strings.getLanguage()? strings.email: ''}
                                        type="text"
                                        variant="outlined"
                                        value={this.state.email}
                                        helperText={this.state.errors.email}
                                    />
                                </div>
                                <div className="text-item">
                                    <Button 
                                        type="submit"
                                        // disabled={!(this.state.email)}
                                        className="common-button signup-button">{strings.getLanguage() ?
                                        strings.submit: ''}</Button>{' '}
                                        <p className="subtext">{strings.getLanguage()? strings.backto_link_text: ''}
                                        &nbsp;<Link className="link-text" to="/">{strings.getLanguage()? strings.login: ''}</Link>
                                        </p>
                                </div>
                            </form>
                        </div>
                    </Col>
                </Row>
             
            </div>
             {this.state.isRedirect ? 
                <Redirect to={{
                    pathname:"/VerificationCode", state:{
                        requestParam: this.state}
                }}></Redirect>: null}
                </>
        );
    }
}
const mapStateToProps = (state) => ({
});
export default connect(mapStateToProps)(ForgotPassword)