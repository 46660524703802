import React, { Component } from 'react';
import { data } from '../../../utils/locales/data';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import {Row, Col, Button} from 'reactstrap';
import '../auth.scss';
import { forgotPasswordVerifyCodeAPI, OAUTH2_CLIENT_ID, sendOTPAPI, setAuthToken, signupVerifyCodeAPI } from '../../../utils/api-helper';
import { showToast, showSpinner } from '../../../utils/common_helper';
import { secToMinutes } from "../../../utils/date-time-helper";
import { Link } from 'react-router-dom';
import OtpInput from "react-otp-input";
import { signInWithCustomToken } from '../../../utils/firebase_helper/auth';
import { saveAuthData } from '../../../actions/auth';
import { set } from "../../../utils/local-storage-helper";

let strings = new LocalizedStrings(data);
class VerificationCode extends Component {
    constructor(props) {
        super(props)

        this.state = {
            otpValue: '',
            otp:'',
            timer: 300,
            defaultTime: 300,
            otpError: false,
            showAlert: false,
            isFromSignup: props?.location?.state?.isFromSignup,
            requestParam: props?.location?.state?.requestParam,
            isSubmitted: false,
            hasErrored: false
        }
    }

    pwdToggle = () => {
        this.setState({ showPassword: !this.state.showPassword });
    };

    componentDidMount(){
        console.log("isFromSignup", this.props);
    }
    
      handleSubmit = (e) => {
        e.preventDefault();
        if (this.state.otpValue && this.state.otpValue.length === 6) {
        this.setState({showLoader: true});
                // console.log("validateAllFields -------- response=>", completeOTP);
                // this.setState({ showLoader: false });
                if (this.state.isFromSignup) {
                    let body = {
                        authPassword: this.state.requestParam?.password,
                        email: this.state.requestParam?.email,
                        firstName: this.state.requestParam?.firstName,
                        lastName: this.state.requestParam?.lastName,
                        oauth2ClientId: OAUTH2_CLIENT_ID,
                        otp: this.state.otpValue,
                        role: this.state.requestParam?.role,
                        signupType: "WEB_APP"
                    }
                    // console.log("body :: ", body);
                    signupVerifyCodeAPI(body).then(response => {
                        signInWithCustomToken(response.data.firebaseToken).then(firebaseUser => {
                            setAuthToken(response.data.accessToken, response.data.refreshToken);
                            set('LUXECREWS_USER_ROLE', this.state.requestParam?.role);
                            set('LUXECREWS_PROFILE_COMPLETED', false);
                            set('LUXECREWS_PROFILE_STEP_NUMBER', 1);
                            this.setState({ showLoader: false });
                            this.props.saveAuthData(response.data);
                            this.props.history.push({pathname:"/Payment"});
                        }).catch(error => {
                            this.setState({ showLoader: false });
                            if (error?.data?.message) {
                                showToast(error?.data?.message, "error");
                              }
                        });
                    }).catch(error => {
                        this.setState({ showLoader: false });
                        if (error?.data?.message) {
                            showToast(error?.data?.message, "error");
                          }
                    });
                } else {
                    let body = {
                        email: this.state.requestParam?.email,
                        oauth2ClientId: OAUTH2_CLIENT_ID,
                        otp: this.state.otpValue
                    }
                    forgotPasswordVerifyCodeAPI(body).then(response => {
                        this.setState({ showLoader: false });
                        setAuthToken(response.data.accessToken, response.data.refreshToken);
                        this.props.history.push({pathname:"/ResetPassword"});
                    }).catch(error => {
                        this.setState({ showLoader: false });
                        if (error?.data?.message) {
                            showToast(error?.data?.message, "error");
                          }
                    });
                }
        }else{
            // if (this.state.otpValue == 0) {
            //     showToast(strings.error_otp, "error");
            // }else{
            //     showToast(strings.error_invalid_code, "error");
            // }
            this.setState({hasErrored: true})
        }
      };

    handleChange = (e) => {
         this.setState({ otpValue: e });
    }

    setTimer = () => {
        this.setState({ timer: this.state.defaultTime }, () => {
            if (this.interval) {
                clearInterval(this.interval);
            }
            this.interval = setInterval(() => {
                this.setState((prevState) => ({ timer: prevState.timer - 1 }));
            }, 1000);
        })
    }

    componentWillMount = () => {
        this.setTimer();
    }

    componentWillUnmount = () => {
        clearInterval(this.interval);
    }

    componentDidUpdate = () => {
        if (this.state.timer === 0) {
            clearInterval(this.interval);
        }
    }

    resendOTP = () => {
        if (this.state.resendClick) {
            return;
        }
        this.state.resendClick = true;
        let body = {
            emailId: this.state.requestParam?.email,
            // firstName: this.state.requestParam?.firstName,
            // lastName: this.state.requestParam?.lastName,
            otpType: this.state.isFromSignup ? "SIGNUP" : "FORGOT_PASSWORD",
            requestFrom: "WEB_APP"
        }
        sendOTPAPI(body).then(response => {
            this.state.resendClick = false;
            this.setTimer();
        }).catch(error => {
            this.state.resendClick = false;
            if (error?.message) {
                showToast(error?.message, "error");
            }
        });
    }

    render() {
        return (
            <div className="common-auth-wrapper">
                {showSpinner(this.state.showLoader)}
               <Row>
                    <Col className="bg-image" >
                        <div className="logo"></div>
                    </Col>
                    <Col className="common-reg-wrapper">
                        <div className="form-wrapper">
                            {strings.getLanguage() ?
                                    <>
                                        <p className="title">{strings.verification_code}</p>
                                        <p className="subtext" style={{marginBottom: '20px'}}>{strings.verification_code_text}</p>
                                    </>
                                : <></>
                            }
                            <form autoComplete="off" onSubmit={this.handleSubmit}>
                                <div className="text-item" style={this.state.hasErrored ? {marginBottom:'5px'}: {marginBottom:'13px'}} >
                                <OtpInput
                                    value={this.state.otpValue}
                                    onChange={this.handleChange}
                                    numInputs={6}
                                    containerStyle="otp-container"
                                    focusStyle="on-focus"
                                    errorStyle="otp-invalid"
                                    isInputNum
                                    shouldAutoFocus
                                    name="otpValue"
                                    inputStyle="otp-input"
                                    hasErrored={this.state.hasErrored}
                                    />
                                </div>
                                {this.state.hasErrored ?
                                    <div className="otp-error-msg">
                                        {this.state.otpValue == 0 ? strings.error_otp : `${strings.error_invalid_code}`}
                                    </div>
                                : null}

                                <div className="links" style={{fontWeight:'500',textAlign:'left', marginBottom:'20px', display:'flex', justifyContent:'space-between'}}>
                                    <p style={{textTransform:'capitalize', margin:0, cursor:'pointer', fontWeight:'500'}} className={`link-text ${(this.state.timer != 0) ? "disable-link" : ""}`} onClick={this.resendOTP}>{strings.getLanguage()? strings.resend_code: ''}</p>
                                    {this.state.timer ? <p>{secToMinutes(this.state.timer)}</p> : null}
                                </div>
                                
                                <div className="text-item">
                                    <Button
                                        type="submit"
                                        //disabled={!(this.state.otpValue)}
                                        className="common-button signup-button">{strings.getLanguage() ?
                                        strings.verify: ''}</Button>{' '}
                                        <p className="subtext">{strings.getLanguage()? strings.backto_link_text: ''}
                                        &nbsp;<Link className="link-text" to="/">{strings.getLanguage()? strings.login: ''}</Link>
                                        </p>
                                </div>
                            </form>
                        </div>
                    </Col>
                </Row>
             
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
});
export default connect(mapStateToProps, {saveAuthData})(VerificationCode)