import React from 'react';
import ForgotPasswordComponent from '../components/user_registration/forgot_password';

const ForgotPassword = props => {
    return (
        <div>
            <ForgotPasswordComponent />
        </div>
    );
}
export default ForgotPassword;