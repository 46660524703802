import React from 'react';
import SettingsComponent from '../screens/settings';

const Settings = props => {
    return (
        <div>
            <SettingsComponent />
        </div>
    );
}
export default Settings;