import React from 'react';
// import SignupComponent from '../screens/settings/change_password';
import HomeComponent from '../components/home';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';

const Home = props => {
    return (
        <div>
            <HomeComponent />
        </div>
    );
}
export default Home;