import React, { Component } from 'react';
import { data } from '../../../utils/locales/data';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import {Row, Col, Button} from 'reactstrap';
import TextField from '@material-ui/core/TextField';
import '../auth.scss';
import EmailIcon from '../../../assets/email.png';
import PasswordIcon from '../../../assets/password.png';
import { validateEmail, validatePassword } from '../../../utils/input-validation';
import { showToast, showSpinner } from '../../../utils/common_helper';
import { Link } from 'react-router-dom';
import { loginAPI, OAUTH2_CLIENT_ID, setAuthToken } from '../../../utils/api-helper';
import { signInWithCustomToken } from '../../../utils/firebase_helper/auth';
import { saveAuthData } from '../../../actions/auth';
import { Redirect } from 'react-router'
import { set, setObj } from "../../../utils/local-storage-helper";

let strings = new LocalizedStrings(data);
class Login extends Component {
    constructor(props) {
        super(props)

        this.state = {
            email: '',
            password: '',
            showPassword: false,
            errors: {},
            isSubmitted: false,
            isRedirect: false
        }
    }

    pwdToggle = () => {
        this.setState({ showPassword: !this.state.showPassword });
    };
    
    validate() {
	    let errors = {};
        let IsValid = true;
      
        if (!validateEmail(this.state.email.trim())) {
            IsValid = false;
            errors["email"] = strings.error_email;
        }
       
        if (this.state["password"] != "" && !validatePassword(this.state.password.trim())) {
            IsValid = false;
            errors["password"] = strings.error_valid_password;
        }else if (!this.state["password"]) {
            errors["password"] = strings.error_password;
        }

        this.setState({ errors: errors });
        return IsValid;
	}
    
      handleSubmit = (e) => {
        console.log('---------------------------------------login------')
        this.setState({isSubmitted: true});
        e.preventDefault();
        console.log('-----------------------------------------check')
        if (this.validate()) {
            console.log('------------------------------------valid')
            this.setState({ showLoader: true });
            let body = {
              emailId: this.state.email,
              oauth2ClientId: OAUTH2_CLIENT_ID,
              password: this.state.password
            }
            loginAPI(body).then(response => {
              signInWithCustomToken(response.data.firebaseToken).then(firebaseUser => {
                setAuthToken(response.data.accessToken, response.data.refreshToken);
                set('LUXECREWS_USER_ROLE', response.data.role);
                set('LUXECREWS_PROFILE_COMPLETED', false);
                set('LUXECREWS_PROFILE_STEP_NUMBER', 1);
                this.setState({ showLoader: false, isRedirect: true });
                console.log('--------------------------------login-----------')
                this.props.saveAuthData(response.data);
              }).catch(error => {
                this.setState({ showLoader: false });
                if (error?.data?.message) {
                    showToast(error?.data?.message, "error");
                  }
              });
            }).catch(error => {
              this.setState({ showLoader: false });
              if (error?.data?.message) {
                showToast(error?.data?.message, "error");
              }
            });
        }
      };

    handleChange = (e) => {
	    let errors = {};
        let IsValid = true;
        this.setState({ [e.target.name]: e.target.value });
        if(this.state.isSubmitted){
            if (e.target.name == "email"){
                if (!validateEmail(e.target.value.trim())) {
                    IsValid = false;
                    errors["email"] = strings.error_email;
                }
            }else if (e.target.name == "password"){
                if (this.state["password"] != "" && !validatePassword(e.target.value.trim())) {
                    IsValid = false;
                    errors["password"] = strings.error_valid_password;
                }else if (!this.state["password"]) {
                    errors["password"] = strings.error_password;
                }
            }
            this.setState({ errors: errors });
            return IsValid;
        }
        
    }

    render() {
        return (
            <>
            <div className="common-auth-wrapper">
                {showSpinner(this.state.showLoader)}
               <Row>
                    <Col className="bg-image" >
                        <div className="logo"></div>
                    </Col>
                    <Col className="common-reg-wrapper">
                        <div className="form-wrapper">
                            {strings.getLanguage() ?
                                    <p className="title" style={{marginBottom: '40px'}}>{strings.login}</p>
                                : <></>
                            }
                            <form autoComplete="off" onSubmit={this.handleSubmit}>
                                <div className="text-item email-field">
                                    <div className="text-icon">
                                        <img src={EmailIcon} />
                                    </div>
                                    <TextField className="text-field" onChange={this.handleChange}
                                        error={this.state.errors.email}
                                        id="email"
                                        name="email"
                                        label={strings.getLanguage()? strings.email: ''}
                                        type="text"
                                        variant="outlined"
                                        value={this.state.email}
                                        helperText={this.state.errors.email}
                                    />
                                </div>

                                <div className="text-item password-field">
                                    <div className="text-icon">
                                        <img src={PasswordIcon} />
                                    </div>
                                    <TextField className="text-field" onChange={this.handleChange}
                                        error={this.state.errors.password}
                                        id="password"
                                        name="password"
                                        value={this.state.password}
                                        label={strings.getLanguage()? strings.password: ''}
                                        type={this.state.showPassword ? "text" : "password"}
                                        variant="outlined"
                                        helperText={this.state.errors.password}
                                    />
                                     <span
                                        className={
                                        this.state.showPassword
                                            ? "show-pwd"
                                            : "hide-pwd"
                                        }
                                        onClick={() => this.pwdToggle()}
                                    ></span>
                                </div>
                                <div className="links"><Link style={{textTransform:'capitalize', fontWeight:'500'}} className={`link-text ${!(this.state.email && this.state.password) ? "" : ""}`} to="/ForgotPassword" >{strings.getLanguage()? strings.forgot_password: ''}</Link></div>
                                <div className="text-item">
                                    <Button 
                                        type="submit" 
                                        // disabled={!(this.state.email && this.state.password)} 
                                        className="common-button signup-button">{strings.getLanguage() ?
                                        strings.login: ''}</Button>{' '}
                                        <p className="subtext">{strings.getLanguage()? strings.signup_link_text: ''}
                                        &nbsp;<Link className="link-text" to="/Signup">{strings.getLanguage()? strings.signUp: ''}</Link>
                                        </p>
                                </div>
                            </form>
                        </div>
                    </Col>
                </Row>
             
            </div>
              {/* {this.state.isRedirect ? <Redirect to={{pathname:"Payment"}}></Redirect>: null} */}

            </>
        );
    }
}
const mapStateToProps = (state) => ({
});
export default connect(mapStateToProps,{saveAuthData})(Login)