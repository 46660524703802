export let en = {
  //common_helper
  ok: 'Ok',
  yes: 'Yes',
  cancel: 'Cancel',
  error: 'Error',
  success: 'Success',

  //permission messages
  permission_gallery_prompt_title: "No photo library permission",
  permission_gallery_message: "Photo Library permission not available. We need you to enable Photo Library permission to help you with this functionality. We will take you to the Settings screen where you can enable this permission.",
  permission_storage_prompt_title: "No storage permission",
  permission_storage_message: "Storage permission not available. We need you to enable Storage permission to help you with this functionality. We will take you to the Settings screen where you can enable Storage permission.",
  permission_camera_prompt_title: "No camera permission",
  permission_camera_message: "We need Camera permission to proceed. The button below will take you to Application Settings where you can enable Camera permission for LuxeCrews.",
  permission_prompt_title: "No location permission",
  permission_prompt_message: "Location permission not available. We need you to enable Location permission to help you with this functionality. We will take you to the Settings screen where you can enable Location permission.",
  permission_notify_prompt_title: "No notification permission",
  permission_notify_prompt_message: "Notification permission not available. We need you to enable Notification permission to help you with this functionality. We will take you to the Settings screen where you can enable Notification permission.",
  setting: "Settings",

  //Error messages
  error_title: "Error",
  error_network_title: "Network Error",
  error_server_message: "Sorry. We are experiencing an error at this time. Please try again in a few minutes.",
  error_network_message: "Please check your internet connection and try again.",
  error_email: "Please enter valid email.",
  error_password: "Please enter password.",
  error_login: "Please enter valid email & password.",
  error_phone_number: "Please enter mobile number.",
  error_role: "Please select role.",
  error_valid_password: "Password should have minimum 6 characters and should be alphanumeric.",
  error_old_new_password: "Old password and new password should not be same.",
  error_old_password: "Password enter old password.",
  error_invalid_mobile: "Please enter a valid mobile number.",
  error_required_otp: "Please enter verification code",
  error_otp: "Please enter a valid OTP.",
  error_invalid_code: "Invalid OTP. Please enter valid OTP.",
  error_code_expire: "OTP expired. Please re-enter or request for a fresh OTP.",
  error_email_already_in_use: "The email address is already in use by another account.",
  error_phone_already_in_use: "Mobile number already in use. Please try with another number.",
  error_select_city: "Please select city.",
  error_select_bedrooms: "Please select number of bedrooms.",
  error_search: "No property data found. Please search again with new search criteria.",
  error_select_start_date: "Please select start date & time.",
  error_select_end_date: "Please select end date & time.",
  error_valid_start_date: "Start date & time must greater than current date & time.",
  error_valid_end_date: "End date & time must greater than start date & time.",
  error_first_name: "Please enter first name.",
  error_valid_first_name: "Only alphabets are allowed in first name.",
  error_last_name: "Please enter last name.",
  error_valid_last_name: "Only alphabets are allowed in last name.",
  error_gender: "Please select gender.",
  error_base_city: "Please select base city.",
  error_valid_base_city_name: "Only alphabets are allowed in base city name.",
  error_base_state: "Please select base state.",
  error_aircraft_type: "Please select aircraft type.",
  error_password_match: "Password and confirm password does not match.",
  error_server_down_msg: "Sorry. We are experiencing an error at this time. Please try again in a few minutes.",
  error_confirm: "Are you sure you want to remove it.",
  error_for_title: "Please enter title.",
  error_resume_pdf: "Please upload resume.",
  error_company_name: "Please enter company name.",
  error_year_experience: "Please enter experience.",
  error_flight_hours: "Please enter total flight hours.",
  error_completion_error: "Please enter valid year.",
  error_invalid_attachment: "Only PDF file format supported. Please select a valid format.",
  error_company_type: "Please select company type.",
  error_tail_number: "Please enter tail number",
  error_empty_card_number: 'Please enter card number.',
  error_invalid_card_number: 'Please enter valid card number.',
  error_invalid_holder_name: 'Only alphabets are allowed in name of card holder.',
  error_empty_bank_account_number: 'Please enter account number.',
  error_empty_routing_number: 'Please enter routing number.',
  error_invalid_routing_number: 'Please enter valid routing number.',
  error_empty_cvv: 'Please enter CVV.',
  error_invalid_cvv: 'Please enter valid CVV.',
  error_empty_expiry_month: 'Please enter valid expiry month.',
  error_empty_expiry_year: 'Please enter valid expiry year.',
  error_empty_ssn_number: 'Please enter valid SSN last four.',
  error_empty_ein_number: 'Please enter valid EIN.',
  error_empty_dob: 'Please enter date of birth.',
  error_country_code: 'Please select country code.',
  error_empty_business_website: 'Please enter business website.',
  error_empty_company_name: 'Please enter company name.',
  error_trip_name:'Please enter trip name',
  error_trip_start_date:'Please select trip start date',
  error_trip_end_date:'Please select trip end date',
  error_valid_job_title:"Only alphabets and numbers are allowed for job title",
  error_job_title:"Please enter job title",
  error_min_flight_time:"Please enter min. hours",
  error_valid_min_flight_time:'Only numbers are allowed for min. hours',
  error_min_flight_time_in_aircraft_type:'Please enter minimum flight time in selected aircraft',
  error_valid_min_flight_time_in_aircraft_type:'Only numbers are allowed for minimum flight time in selected aircraft',
  error_min_experience:"Please enter minimum experience",
  error_valid_min_experience:'Only numbers are allowed for minimum experience',

  //Login
  login: "Login",
  email: "Email",
  password: "Password",
  forgot_password: "Forgot Password?",
  signup_link_text: "Don't have an account?",
  signUp: "Sign up",

  //Signup
  first_name: "First Name",
  last_name: "Last Name",
  role: "Role",
  confirm_password: "Confirm Password",
  signin_link_text: "Already have an account?",
  create_an_account: "Create an account to continue",
  pilot: "Pilot",
  flight_attendant: "Flight Attendant",
  aircraft_owner: "Aircraft Owner",
  aviation_company: "Aviation Company",
  general_role: "General",

  //Forgot Password
  submit: "Submit",
  backto_link_text: "Back to ",
  forgot_password_label: "Forgot Password",
  forgot_password_text: "Enter the email address associated with your account below. We'll send an email with a verification code to allow you to reset your password.",

  //Verification Code
  verification_code: "Verification Code",
  verification_code_text: "Enter the verification code we just sent you on your email address",
  resend_code: "Resend Code",
  verify: "Verify",

  //Reset Password
  reset_password: "Reset Password",
  resend_code_text: "Your new password must be different from previously used password.",
  new_password: "New Password",
  reset: "Reset",

  //Pilot Profile
  next: "Next",
  code: "Code",
  gender: "Gender",
  optional: "Optional",
  base_city: "Base City",
  twitter: "Twitter",
  facebook: "Facebook",
  linkedin: "Linkedin",
  instagram: "Instagram",
  base_state: "Base State",
  about_me: "About Me",
  social_media: "Social Media",
  edit_profile: "Edit Profile",
  date_of_birth: "Date of Birth",
  aircraft_type: "Aircraft Type",
  mobile_number: "Mobile Number",
  base_location: "Base Location",
  personal_info: "Personal Information",
  of: " of ",

  //Qualifications and Experience
  pdf: "PDF",
  add: "Add",
  clear: "Clear",
  remove: "Remove",
  resume: "Resume",
  title: "Title",
  year: "Year",
  month: "Month",
  about_me: "About Me",
  add_more: "Add More",
  logBook: "Logbook",
  characters: "characters",
  description: "Description",
  experience: "Experience",
  experiences: "Experiences",
  completed_from: "Completed From",
  year_of_experience: "Year of Experience",
  year_of_completion: "Year of Completion",
  add_attachment: "Add Attachment",
  total_flight_hours: "Total Flight Hours",
  degrees_and_certs: "Degrees & Certifications",
  aircraft_qualification: "Aircraft Qualifications",
  qual_and_exp: "Qualifications and Experience",
  degree_cert_name: "Degree / Certification Name",
  org_institute_name: "Organization / Institutes Name",
  add_qual_text: 'Please click on the "Plus" button to select your qualification',
  uploading_resume: 'Uploading Resume:  ',
  uploading_deg: 'Uploading Degrees & Certifications\nAttachment ',
  uploading_logbook: 'Uploading Logbook\nAttachment ',

  //Aircraft Informtion
  company_name: "Company Name",
  tail_number: "Tail Number",
  aircraft_info: "Aircraft Information",

  //Gender Options
  male: "Male",
  female: "Female",
  other: "Other",

  //Bottom Sheet
  done: 'Done',
  select: "Select",
  no_data_found: "No data found",
  search: "Search",
  confirm: "Confirm",
  confirmation: 'Confirmation',
  select_code: "Select code",
  type_here: "Type here...",
  search_country: "Search country",
  select_country_code: "Select country code",

  //Select media
  select_media: 'Select media…',
  take_photo: 'Take a photo',
  camera_roll: 'Choose from gallery',
  select_image: 'Select a photo',
  crop_txt: 'Crop',
  rotate_txt: 'Rotate',

  //Drawer
  more:"More",
  chat: "Chat",
  home: "Home",
  logout: "Logout",
  settings: "Settings",
  payment: "Payment",
  subscription: 'Subscription',
  my_profile: "My Profile",
  app_version: "App Version",
  contact: "Contact LuxeCrews",
  trip_calendar: "Trip Calendar",
  privacy_policy: "T&C / Privacy Policy",
  mark_availability: "Mark Availability",
  create_aviation_company: "Create Aviation Company",
  logout_msg: "Are you sure you want to logout?",

  //Settings Screen
  notifications: "Notifications",
  change_password: "Change Password",

  //Change Password
  update: 'Update',
  old_password: 'Old Password',

  //Mark Availability
  trip_scheduled: "Trip Scheduled",
  marked_available: "Marked Available",
  mark_as_available: "Mark as Available",
  avail_marked_success: "Availability\n marked successfully",
  //Subscription
  active_plan: 'Active Plan',
  subscribe: 'SUBSCRIBE',
  cancel_auto_renewal: 'Cancel Auto Renewal',
  benefits: 'Benefits :',
  plan_details: 'Plan Details :',
  info_title: 'If you wish to unsubscribe the plans  Kindly go through the following Information:',
  expiry_date: "Expiry Date:",
   unsubscribe: 'UNSUBSCRIBE',
   existing_card: 'Continue With existing card',
   add_card: 'Add Card',
   pay_now: 'Pay Now',
   unlimited_job_posts: 'Unlimited Job Postings',
   no_of_aircrafts: 'No of Aircraft Added: ',
   no_of_general: 'No of General : ',
   no_of_attendent: 'No of Attendants: ',
   activate_auto_renewal: 'Activate Auto Renewal',
   payment_success: 'Payment Successful! ',
   activate: 'Activate',
   no_of_pilot: 'No of Pilots:',
   auto_renewal_title: 'Are you sure you wish to activate auto renewal? ',
   auto_renewal_message: 'You can activate auto renewal with your existing card or new card. ',
   alert_title: 'Alert',
   subscribe_alert_message: 'Please cancel the existing subscription so that you can upgrade to the selected plan.',
   
   cancel_auto_renewal_alert_message: 'When your subscription ends you will not be charged and will need to re-subscribe to access the LuxeCrews platform. ',
  subscription_plan:'Subscription Plans',
  subscribe_confirm_message: 'Are you sure you want to subscribe following plan? Please click on the proceed button to continue.',
  //Payment
  no_card_info_added: 'No card details added',
  no_bank_info_added: 'No bank details added',
  add_new_card: 'Add New Card',
  add_bank_details: 'Add Bank Details',
  bank_details: "Bank Details",
  edit: 'Edit',
  proceed: 'Proceed',
  set_auto_renewal: 'Set Auto Renewal',

  no: 'No',
  delete: 'Delete',
  account_type: 'Account Type',
  account_holder_name: 'Account Holder Name',
  account_number: 'Account Number',
  confirm_account_number: 'Confirm Account Number',
  routing_number: 'Routing Number',
  individual: 'Individual',
  company: 'Company',
  name_on_card: 'Name on Card',
  card_number: 'Card Number',
  expiry_month: 'Expiry Month',
  expiry_year: 'Expiry Year',
  cvv: 'CVV',
  set_as_primary: 'Set as a primary',
  expires_labels: 'Expires',
  ssn_number: 'SSN (Last 4 Digit)',
  ein_number: 'Employer Identification Number (EIN)',
  country_code: 'Country Code',
  currency: 'Currency',
  vat_number: 'VAT Number (Optional)',
  address_line_one_optional: 'Address Line 1 (Optional)',
  address_line_two_optional: 'Address Line 2 (Optional)',
  city_optional: 'City (Optional)',
  state_optional: 'State (Optional)',
  zip_optional: 'Zip (Optional)',
  business_website: 'Business Website',
  mobile_number_optional: 'Mobile Number (Optional)',
  email_optional: 'Email (Optional)',
  confirm_delete_card: 'Are you sure want to delete this card?',
  confirm_delete_bank: 'Are you sure want to delete this bank?',

  //Mobile screen
  mobile_screen_msg: 'For the best mobile view experience Please download the mobile app',

  //Page not found screen
  page_not_found_label: 'Page not found',
  
};