import React from 'react';
import PaymentComponent from '../components/payment';

const Payment = props => {
    return (
        <div>
            <PaymentComponent />
        </div>
    );
}
export default Payment;