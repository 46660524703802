import React, { Component } from 'react';
import { data } from '../../../utils/locales/data';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'reactstrap';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import '../auth.scss';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import UserIcon from '../../../assets/user.png';
import RoleIcon from '../../../assets/role.png';
import EmailIcon from '../../../assets/email.png';
import PasswordIcon from '../../../assets/password.png';
import { validateEmail, validateEqualInputs, validatePassword, validateTextOnly } from '../../../utils/input-validation';
import { sendOTPAPI } from '../../../utils/api-helper';
import { showToast, showSpinner } from '../../../utils/common_helper';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router'
import { TrendingUpRounded } from '@material-ui/icons';

let strings = new LocalizedStrings(data);

class Signup extends Component {
    constructor(props) {
        super(props)

        this.state = {
            language: this.props?.language ? this.props.language : 'en',
            firstName: '',
            lastName: '',
            email: '',
            role: '',
            password: '',
            confirmPassword: '',
            showPassword: false,
            showConfirmPassword: false,
            errors: {},
            isSubmitted: false
        }
    }
    validate() {
        let errors = {};
        let IsValid = true;

        if (this.state["firstName"] != "" && !validateTextOnly(this.state.firstName.trim())) {
            IsValid = false;
            errors["firstName"] = strings.error_valid_first_name;
        } else if (!this.state["firstName"]) {
            errors["firstName"] = strings.error_first_name;
        }

        if (this.state["lastName"] != "" && !validateTextOnly(this.state.lastName.trim())) {
            IsValid = false;
            errors["lastName"] = strings.error_valid_last_name;
        } else if (!this.state["lastName"]) {
            errors["lastName"] = strings.error_last_name;
        }

        if (!validateEmail(this.state.email.trim())) {
            IsValid = false;
            errors["email"] = strings.error_email;
        }

        if (!this.state["role"]) {
            IsValid = false;
            errors["role"] = strings.error_role;
        }

        if (this.state["password"] != "" && !validatePassword(this.state.password.trim())) {
            IsValid = false;
            errors["password"] = strings.error_valid_password;
        } else if (!this.state["password"]) {
            errors["password"] = strings.error_password;
        }

        if (!validateEqualInputs(this.state.password, this.state.confirmPassword)) {
            IsValid = false;
            errors["confirmPassword"] = strings.error_password_match;
        }

        this.setState({ errors: errors });
        return IsValid;
    }

    handleSubmit = (e) => {
        this.setState({ isSubmitted: true });
        e.preventDefault();
        if (this.validate()) {
            this.setState({ showLoader: true });
            let body = {
                emailId: this.state.email,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                otpType: "SIGNUP",
                requestFrom: 'WEB_APP'
            }
            sendOTPAPI(body).then(response => {
                this.setState({ showLoader: false, isRedirect: true });
            }).catch(error => {
                this.setState({ showLoader: false });
                if (error?.data?.message) {
                    showToast(error?.data?.message, "error");
                }
            });
        }
    };

    handleChange = (e) => {
        let errors = {};
        let IsValid = true;
        this.setState({ [e.target.name]: e.target.value });
        if (this.state.isSubmitted) {
            if (e.target.name == "firstName") {
                if (this.state["firstName"] != "" && !validateTextOnly(e.target.value.trim())) {
                    IsValid = false;
                    errors["firstName"] = strings.error_valid_first_name;
                } else if (!this.state["firstName"]) {
                    errors["firstName"] = strings.error_first_name;
                }
            } else if (e.target.name == "lastName") {
                if (this.state["lastName"] != "" && !validateTextOnly(e.target.value.trim())) {
                    IsValid = false;
                    errors["lastName"] = strings.error_valid_last_name;
                } else if (!this.state["lastName"]) {
                    errors["lastName"] = strings.error_last_name;
                }
            } else if (e.target.name == "email") {
                if (!validateEmail(e.target.value.trim())) {
                    IsValid = false;
                    errors["email"] = strings.error_email;
                }
            } else if (e.target.name == "role") {
                setTimeout(() => {
                    if (this.state["role"] == "") {
                        IsValid = false;
                        errors["role"] = strings.error_role;
                    }
                }, 500)

            } else if (e.target.name == "password") {
                if (this.state["password"] != "" && !validatePassword(e.target.value.trim())) {
                    IsValid = false;
                    errors["password"] = strings.error_valid_password;
                } else if (!this.state["password"]) {
                    errors["password"] = strings.error_password;
                }
            } else if (e.target.name == "confirmPassword") {
                if (!validateEqualInputs(this.state.password, e.target.value)) {
                    IsValid = false;
                    errors["confirmPassword"] = strings.error_password_match;
                }
            }
            this.setState({ errors: errors });
            return IsValid;
        }

    }

    toggle = () => {
        this.setState({ modal: !this.state.modal })
    }

    pwdToggle = () => {
        this.setState({ showPassword: !this.state.showPassword });
    }

    cpwdToggle = () => {
        this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
    }

    render() {
        return (
            <>
                <div className="common-auth-wrapper">
                    {showSpinner(this.state.showLoader)}
                    <Row>
                        <Col className="bg-image" >
                            <div className="logo"></div>
                        </Col>
                        <Col className="common-reg-wrapper">
                            <div className="form-wrapper">
                                {strings.getLanguage() ?
                                    <>
                                        <p className="title">{strings.signUp}</p>
                                        <p className="subtext">{strings.create_an_account}</p>
                                    </>
                                    : <></>
                                }
                                <form autoComplete="off" onSubmit={this.handleSubmit}>
                                    <div className="text-item">
                                        <div className="text-icon">
                                            <img src={UserIcon} />
                                        </div>
                                        <TextField className="text-field" onChange={this.handleChange}
                                            error={this.state.errors.firstName}
                                            id="firstName"
                                            name="firstName"
                                            label={strings.getLanguage() ? strings.first_name : ''}
                                            type="text"
                                            variant="outlined"
                                            value={this.state.firstName}
                                            helperText={this.state.errors.firstName}
                                            inputProps={{ maxLength: 50 }}
                                        />
                                    </div>
                                    <div className="text-item">
                                        <div className="text-icon">
                                            <img src={UserIcon} />
                                        </div>
                                        <TextField className="text-field" onChange={this.handleChange}
                                            error={this.state.errors.lastName}
                                            id="lastName"
                                            name="lastName"
                                            label={strings.getLanguage() ? strings.last_name : ''}
                                            type="text"
                                            variant="outlined"
                                            value={this.state.lastName}
                                            helperText={this.state.errors.lastName}
                                            inputProps={{ maxLength: 50 }}
                                        />
                                    </div>
                                    <div className="text-item email-field">
                                        <div className="text-icon">
                                            <img src={EmailIcon} />
                                        </div>
                                        <TextField className="text-field" onChange={this.handleChange}
                                            error={this.state.errors.email}
                                            id="email"
                                            name="email"
                                            label={strings.getLanguage() ? strings.email : ''}
                                            type="text"
                                            variant="outlined"
                                            value={this.state.email}
                                            helperText={this.state.errors.email}
                                            inputProps={{ maxLength: 60 }}
                                        />
                                    </div>
                                    <div className="text-item">
                                        <div className="text-icon">
                                            <img src={RoleIcon} />
                                        </div>
                                        <FormControl variant="outlined" className="role-dropdown" error={this.state.errors.role}>
                                            {/* <InputLabel htmlFor="outlined-age-native-simple">{strings.getLanguage() ? strings.role : <></>}</InputLabel> */}
                                            <InputLabel id="demo-simple-select-filled-label">{strings.getLanguage() ? strings.role : ''}</InputLabel>
                                            <Select onChange={this.handleChange}
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                name="role"
                                                value={this.state.role}
                                            // label={strings.getLanguage() ? strings.role : ''}
                                            >
                                                <MenuItem className="dd-item" value="PILOT">{strings.getLanguage() ? strings.pilot : ''}</MenuItem>
                                                <MenuItem className="dd-item" value="FLIGHT_ATTENDANT">{strings.getLanguage() ? strings.flight_attendant : ''}</MenuItem>
                                                <MenuItem className="dd-item" value="GENERAL">{strings.getLanguage() ? strings.general_role : ''}</MenuItem>
                                            </Select>
                                            <FormHelperText>{this.state.errors.role}</FormHelperText>
                                        </FormControl>
                                    </div>

                                    <div className="text-item">
                                        <div className="text-icon">
                                            <img src={PasswordIcon} />
                                        </div>
                                        <TextField className="text-field" onChange={this.handleChange}
                                            error={this.state.errors.password}
                                            id="password"
                                            name="password"
                                            value={this.state.password}
                                            label={strings.getLanguage() ? strings.password : ''}
                                            type={this.state.showPassword ? 'text' : 'password'}
                                            variant="outlined"
                                            helperText={this.state.errors.password}
                                        />
                                        <span className={this.state.showPassword ? 'show-pwd' : 'hide-pwd'} onClick={() => this.pwdToggle()}></span>
                                    </div>
                                    <div className="text-item">
                                        <div className="text-icon">
                                            <img src={PasswordIcon} />
                                        </div>
                                        <TextField className="text-field" onChange={this.handleChange}
                                            error={this.state.errors.confirmPassword}
                                            id="confirmPassword"
                                            value={this.state.confirmPassword}
                                            name="confirmPassword"
                                            label={strings.getLanguage() ? strings.confirm_password : ''}
                                            type={this.state.showConfirmPassword ? 'text' : 'password'}
                                            variant="outlined"
                                            helperText={this.state.errors.confirmPassword}
                                        />
                                        <span className={this.state.showConfirmPassword ? 'show-pwd' : 'hide-pwd'} onClick={() => this.cpwdToggle()}></span>
                                    </div>
                                    <div className="text-item">
                                        <Button 
                                            type="submit"
                                            // disabled={!(this.state.firstName && this.state.lastName && this.state.email && this.state.role && this.state.password && this.state.confirmPassword)}
                                            className="common-button signup-button">{strings.getLanguage() ?
                                            strings.signUp : ''}</Button>{' '}
                                        <p className="subtext">{strings.getLanguage() ? strings.signin_link_text : ''}
                                            &nbsp;<Link className="link-text" to="/">{strings.getLanguage() ? strings.login : ''}</Link>
                                        </p>
                                    </div>
                                </form>
                            </div>
                        </Col>
                    </Row>

                </div>
                {this.state.isRedirect ?
                    <Redirect to={{
                        pathname: "/VerificationCode", state: {
                            isFromSignup: true,
                            requestParam: this.state
                        }
                    }}></Redirect> : null}
            </>
        );
    }
}
const mapStateToProps = (state) => ({
});
export default connect(mapStateToProps)(Signup)