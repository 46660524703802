import React from 'react';
import SignupComponent from '../components/user_registration/sign_up';

const Signup = props => {
    return (
        <div>
            <SignupComponent />
        </div>
    );
}
export default Signup;