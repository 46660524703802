import React, { Component } from 'react';
import { connect } from "react-redux";
import moment from 'moment';
import LocalizedStrings from 'react-localization';
import { data } from '../../../utils/locales/data';
import { Button, Row, Col } from 'reactstrap';
import { addBankDetailsAPI, getCountryListAPI } from '../../../utils/api-helper';
import { showToast, showSpinner, confirmAlert } from '../../../utils/common_helper';
import { get } from '../../../utils/local-storage-helper';
import { withStyles } from "@material-ui/core/styles";
import './style.scss';
import TextField from '@material-ui/core/TextField';
import { saveBankDetails, initializeStripe } from '../../../utils/stripe-helper';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { validateWebsite } from '../../../utils/input-validation';
import EmptyAction from '../../empty-action';
import BankIcon from '../../../assets/bank_icon.png';
import UserIcon from '../../../assets/user_icon.png';
import FlagIcon from '../../../assets/flag_icon.png';
import CompanyIcon from '../../../assets/company_icon.svg';

import 'date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

let strings = new LocalizedStrings(data);
class AddBankDetails extends Component {

    constructor(props) {
        super(props)

        this.state = {
            accountTypeList: [
                { value: strings.individual, id: "Individual" },
                { value: strings.company, id: "Company" }
            ],
            accountHolderType: "",
            accountNumber: "",
            routingNumber: "",
            addressLineOne: "",
            addressLineTwo: "",
            city: "",
            state: "",
            zip: "",
            businessWebsite: "",
            stdCode: { code: "+1", name: "United States" },
            mobileNumber: "",
            countryCode: "",
            currency: "USD",
            countryId: "",
            selectedCountry: '',
            countryListOptions: [],
            stripeToken: "",

            //Indivisual fields
            firstName: "",
            lastName: "",
            dob: new Date(2000, 0, 1),
            dobDate: "",
            dobMonth: "",
            dobYear: "",
            last4ssn: "",
            email: "",
            dateOfBirth: null,
            selectedDate: null,

            //Company fields 
            companyName: '',
            ein: "",
            vatNumber: "",

            //Validation and bottom sheet flags
            errors:{},

            isFirstTimeLoaded: false,
            isConfirmAlert: false,
            isAddBankDetails: false,

            isSubmitted: false
        }
        //Set first account type to Indivisual
    }

    componentDidMount = () => {
        initializeStripe();
        this.state.selectedType = this.state.accountTypeList[0];
        this.state.accountHolderType = this.state.selectedType?.value;
        this.getCountryList();
     }

     componentDidUpdate(prevProps) {
        let role = get("LUXECREWS_USER_ROLE");
        if (prevProps.authData !== this.props.authData) {
            if(this.state.isFirstTimeLoaded === false){
                if (role !== 'GENERAL') {
                    this.getCountryList();
                }
            }
        }
    }

     handleDateChange = (date) => {
         this.setState({selectedDate: date})
     }

    /**
     * Function to get bank details from backend server
     */
    getCountryList = () => {
        let countryCodeObject = {};
        let returnData = [];
        let countryResponseData = [];
        getCountryListAPI().then(response => {
            this.setState({showLoader: false});
            if (response.data && response.data.countryDetails) {
                countryResponseData = response.data.countryDetails;
                //Format Country code object for currency and country code
                countryResponseData.map((i) => {
                    countryCodeObject.value = i.countryCode;
                    countryCodeObject.id = i.countryId;
                    countryCodeObject.countryCurrency = i.countryCurrency;
                    countryCodeObject.countryCurrencyId = i.countryCurrencyId;
                    countryCodeObject.countryId = i.countryId;
                    countryCodeObject.countryName = i.countryName;
                    returnData.push(countryCodeObject);
                })
                this.setState({ countryListOptions: returnData, countryCode: returnData[0].value, countryId: returnData[0].id, isFirstTimeLoaded: true });
            }
        }).catch(error => {
            this.setState({showLoader: false});
            this.handleError(error.data)
        });
    }
    
    handleSubmit = () => {
        this.setState({isSubmitted: true})
        if (this.validate()) {
            this.setState({ showLoader: true });
            if (this.state.showLoader) {
                return;
            }
            this.state.showLoader = true;
            this.setState({ showLoader: true });
            let accountHName;
            if( this.state.accountHolderType === 'Individual'){
                accountHName = this.state.firstName + ' ' + this.state.lastName;
            }else if(this.state.accountHolderType == 'Company'){
                accountHName = this.state.companyName;
            }
            let params = {
                account_number: this.state.accountNumber, //remove all added white spaces
                //account_number: '000123456789',
                // countryCode: this.state.countryCode.toLowerCase(), //TODO change country code as dynamic string
                // countryCode: 1,
                country: this.state.countryCode,
                currency: this.state.currency.toLowerCase(),  //TODO change currency as dynamic string
                account_holder_type: this.state.selectedType?.value.toLowerCase(),
                routing_number: this.state.routingNumber,
                account_holder_name: accountHName,
            }

            saveBankDetails(params, (status, response) => {
                if (status === 200){
                    let website = this.state.businessWebsite.trim();
                    if (!website.startsWith("https://")) {
                        if (website.startsWith("http://")) {
                            website.replace("http://", "https://");
                        } else {
                            website = "https://" + website;
                        }
                    }
                    let payload = {
                        stripeToken: response.id,
                        countryCode: this.state.countryCode, //this.state.countryCode.toString(),
                        // countryId: this.state.countryId,
                        countryId: this.state.countryId,
                        last4ssn: this.state.last4ssn,
                        // last4ssn: response.bank_account.last4,
                        ein: this.state.ein,
                        currency: this.state.currency.toLowerCase(),
                        companyName: this.state.companyName.trim(),
                        firstName: this.state.firstName.trim(),
                        lastName: this.state.lastName.trim(),
                        accountHolderType: this.state.accountHolderType.toUpperCase(),
                        businessWebsite: website,
                        phoneNumber: this.state.phoneNumber ? (this.state.stdCode?.code + this.state.phoneNumber?.trim()) : '',
                        email: this.state.email.trim(),
                        vatNumber: this.state.vatNumber.trim(),
                        addressLineOne: this.state.addressLineOne.trim(),
                        addressLineTwo: this.state.addressLineTwo.trim(),
                        city: this.state.city.trim(),
                        state: this.state.state.trim(),
                        zip: this.state.zip.trim(),
                        dobDate: this.state.dobDate,
                        dobMonth: this.state.dobMonth,
                        dobYear: this.state.dobYear,
                    }
                    addBankDetailsAPI(payload).then(res => {
                        showToast(res?.message, "success");
                        //this.props.navigation.pop();
                        this.setState({ showLoader: false });
                        this.props.onAddBank();
                        this.handleCancel();
                    }).catch(error => {
                        this.handleError(error.data)
                    })
                } else{
                    this.setState({ showLoader: false });
                    this.handleError(response.error);
                }
            })
        }
    }

    handleCancel = () => {
        this.setState({isAddBankDetails: false, isSubmitted: false});
        this.clearForm();
    }

    handleError = (error) => {
        this.setState({ showLoader: false }, () => {
            if (error?.message) {
                showToast(error?.message, "error");
            }
        });
    }

    validate = () => {
        let errors = {};
        let isValid = true;
        if (!this.state.accountHolderType) {
            isValid = false;  
        }
        if (!this.state.accountNumber || !this.state.accountNumber.trim().length) { 
            isValid = false;
            errors["accountNumber"] = strings.error_empty_bank_account_number;
        }
        if (!this.state.routingNumber || !this.state.routingNumber.trim().length) {
            isValid = false;
            errors["routingNumber"] = strings.error_empty_routing_number;
        }else if (!this.state.routingNumber || this.state.routingNumber.trim().length < 9) {
            isValid = false;
            errors["routingNumber"] = strings.error_invalid_routing_number;
        }

        if (!this.state.countryCode) {
            isValid = false;
            errors["countryCode"] = strings.error_country_code;
        }
        if (!this.state.businessWebsite || !this.state.businessWebsite.trim().length || !validateWebsite(this.state.businessWebsite.trim())) {
            isValid = false;
            errors["businessWebsite"] = strings.error_empty_business_website;
        }
        if (this.state.accountHolderType && this.state.accountHolderType == "Individual") {
            if (!this.state.last4ssn || !this.state.last4ssn.trim().length) { 
                isValid = false;
                errors["last4ssn"] = strings.error_empty_ssn_number;
            }else if(this.state.last4ssn.trim().length && this.state.last4ssn.trim().length < 4){
                isValid = false;
                errors["last4ssn"] = strings.error_empty_ssn_number;
            }

            if (!this.state.firstName || !this.state.firstName.trim().length) {
                isValid = false;
                errors["firstName"] = strings.error_first_name;
            }
            if (!this.state.lastName || !this.state.lastName.trim().length) {
                isValid = false;
                errors["lastName"] = strings.error_last_name;
            }
            if (!this.state.phoneNumber || (!this.state.phoneNumber.trim().length && this.state.phoneNumber.trim().length != 10)) {
                isValid = false;
                errors["phoneNumber"] = strings.error_phone_number;
            }
            if (!this.state.dateOfBirth) {
                isValid = false;
                errors["dateOfBirth"] = strings.error_empty_dob;
            }
        } else {
            if (!this.state.ein || !this.state.ein.trim().length) {
                isValid = false;
                errors["ein"] = strings.error_empty_ein_number;
            }
            if (!this.state.companyName || !this.state.companyName.trim().length) {
                isValid = false;
                errors["companyName"] = strings.error_empty_company_name;
            }
        }

        this.setState({ errors: errors });
        return isValid;
    }

    handleChange = (e, from) => {
	    let errors = {};
        let isValid = true;
        if(from === 'dob'){
            let day;
            let month;
            let year;
            let date = new Date(e);
            day = date.getUTCDate();
            month = date.getUTCMonth() + 1;
            year = date.getUTCFullYear();
            this.setState({ dateOfBirth: e, dobDate: day, dobMonth: month, dobYear: year });
        }
        if(e && e.target && e.target.name != "accountType"){
            if(from === 'number'){
                this.setState({ [e.target.name]: e.target.value.replace(/[^0-9]/g, '') });
            } else {
                this.setState({ [e.target.name]: e.target.value });
            }
        }
        
        if(e && e.target && e.target.name == "accountType"){
            this.setState({ accountHolderType: e.target.value });
            this.setState({isSubmitted: false})
            this.clearForm();
            if (!this.state.accountHolderType) {
                isValid = false;
            }
        }
        
        if(this.state.isSubmitted){
            if(e && e.target &&  e.target.name == "accountNumber"){
                if ( !e.target.value.replace(/[^0-9]/g, '') || !e.target.value.replace(/[^0-9]/g, '').length) {
                    isValid = false;
                    errors["accountNumber"] = strings.error_empty_bank_account_number;
                }
            }
            if(e && e.target &&  e.target.name == "routingNumber" ){
                if ( !e.target.value.replace(/[^0-9]/g, '') || !e.target.value.replace(/[^0-9]/g, '').length) {
                    isValid = false;
                    errors["routingNumber"] = strings.error_empty_routing_number;
                } else if (!e.target.value.replace(/[^0-9]/g, '') || e.target.value.replace(/[^0-9]/g, '').length < 9) {
                    isValid = false;
                    errors["routingNumber"] = strings.error_invalid_routing_number;
                }
            }
    
            if(e && e.target &&  e.target.name == "countryCode"){
                if (!this.state.countryCode) {
                    isValid = false;
                    errors["countryCode"] = strings.error_country_code;
                }
            }
    
            if(e && e.target &&  e.target.name == "businessWebsite"){
                if (!this.state.businessWebsite || !this.state.businessWebsite.trim().length || !validateWebsite(this.state.businessWebsite.trim())) {
                    isValid = false;
                    errors["businessWebsite"] = strings.error_empty_business_website;
                }
            }
            
            if (this.state.accountHolderType && this.state.accountHolderType == "Individual") {
    
                if(e && e.target &&  e.target.name == "last4ssn"){
                    if (!e.target.value.replace(/[^0-9]/g, '') || !e.target.value.replace(/[^0-9]/g, '').length) {
                        isValid = false;
                        errors["last4ssn"] = strings.error_empty_ssn_number;
                    }else if(e.target.value.replace(/[^0-9]/g, '').length && e.target.value.replace(/[^0-9]/g, '').length < 4){
                        isValid = false;
                        errors["last4ssn"] = strings.error_empty_ssn_number;
                    }
                }
                
                if(e && e.target && e.target.name == "firstName"){
                    if (!this.state.firstName || !this.state.firstName.trim().length) {
                        isValid = false;
                        errors["firstName"] = strings.error_first_name;
                    }
                }
    
                if(e && e.target &&  e.target.name == "lastName"){
                    if (!this.state.lastName || !this.state.lastName.trim().length) {
                        isValid = false;
                        errors["lastName"] = strings.error_last_name;
                    }
                }
                
                if(e && e.target &&  e.target.name == "phoneNumber"){
                    if (!e.target.value.replace(/[^0-9]/g, '') || (e.target.value.replace(/[^0-9]/g, '').length && e.target.value.replace(/[^0-9]/g, '').length != 10)) {
                        isValid = false;
                        errors["phoneNumber"] = strings.error_phone_number;
                    }
                }
               
                if(e && e.target &&  e.target.name == "dateOfBirth"){
                    if (!this.state.dateOfBirth) {
                        isValid = false;
                        errors["dateOfBirth"] = strings.error_empty_dob;
                    }
                }
                
            } else {
                if(e && e.target &&  e.target.name == "ein"){
                    if (!e.target.value.replace(/[^0-9]/g, '') || !e.target.value.replace(/[^0-9]/g, '').length) {
                        isValid = false;
                        errors["ein"] = strings.error_empty_ein_number;
                    }
                }
               
                if(e && e.target &&  e.target.name == "companyName"){
                    if (!this.state.companyName || !this.state.companyName.trim().length) {
                        isValid = false;
                        errors["companyName"] = strings.error_empty_company_name;
                    }
                }
            }
            this.setState({ errors: errors });
            return isValid;
        }    
    }

    clearForm = () => {
        this.setState({ 
            accountNumber: '',
            routingNumber: '',
            addressLineOne: '',
            addressLineTwo: '',
            city: '',
            state: '',
            zip: '',
            routingNumber: '',
            businessWebsite: '',
            firstName: '',
            lastName: '',
            last4ssn: '',
            email: '',
            phoneNumber: '',
            dateOfBirth: null,
            companyName: '',
            ein: '',
            vatNumber: '',
        });
    }

    
    onAddBankDetails = () => {
        this.setState({isAddBankDetails: true, isSubmitted: false});
    }

    onConfirmDelete = () => {
        this.setState({isConfirmAlert: false});
        this.props.onDeleteBank()
    }

    closeConfirmAlert = () => {
        this.setState({isConfirmAlert: false})
    }

    openConfirmAlert = () => {
        this.setState({isConfirmAlert: true})
    }

    getMaximumDate = () => {
        let preDate = moment().subtract(18, 'years');
        this.state.maxDate = preDate;
        return preDate ? new Date(preDate) : "";
    }

    render() {
        const {bankDetails} = this.props;
        const {isAddBankDetails, accountTypeList, countryListOptions, isConfirmAlert} = this.state;
        
        return (
            <>
                <div className="common-page-wrapper">
                {showSpinner(this.state.showLoader)}
                    <Row md={2} className="common-page-header">
                        <Col className="title">{isAddBankDetails ? strings.add_bank_details : strings.bank_details}</Col> 
                        {isAddBankDetails?
                            <Col className="header-icons">
                                <Button className="negative-btn" onClick={this.handleCancel}>{strings.cancel}</Button>
                                <Button type="submit" className="positive-btn" onClick={this.handleSubmit}>{strings.submit}</Button>
                            </Col>
                        : bankDetails
                        ? <Col className="header-icons">
                            <Button className="common-button common-delete-button" onClick={() => this.openConfirmAlert()}>{strings.delete}</Button>
                        </Col>
                        : null}
                    </Row>
                    {!isAddBankDetails ? 
                        <div className="bank-details-wrapper">
                            {bankDetails ?
                                <div className="details-info">
                                    <div className="bank-details">
                                        <img src={BankIcon} />
                                        <p className="account-no">{`XXXX XXXX XXXX ${bankDetails.last4}`}</p>
                                        <p className="bank-name">{bankDetails.bankName}</p>
                                        <Row md={2} className="account-holder-info">
                                            <Col>
                                                <img src={bankDetails.accountHolderType === "individual" ? UserIcon : CompanyIcon} /> 
                                                {bankDetails.accountHolderName}  {/* TODO remove the binding*/}
                                            </Col> 
                                            <Col>
                                                <img src={FlagIcon} /> {bankDetails.countryCode}
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                : <EmptyAction icon={BankIcon} message={strings.no_bank_info_added} button={true} buttonText={strings.add_bank_details} buttonClick={this.onAddBankDetails}/>
                            }
                        </div> 
                        : null
                    }

                    {isAddBankDetails 
                    ?   <div className="page-content add-bank-details-wrapper">
                        
                            <form autoComplete="off" onSubmit={this.handleSubmit}>
                                <Row md={2}>
                                    <Col className="form-item">
                                        <Row>
                                            <Col className="text-item">
                                                <FormControl variant="outlined" error={this.state.errors.role}>
                                                    <InputLabel htmlFor="outlined-age-native-simple">{strings.getLanguage()? strings.account_type: <></>}</InputLabel>
                                                    <Select onChange={this.handleChange}
                                                        name="accountType"
                                                        value={this.state.selectedAccountType}
                                                        native
                                                        label={strings.getLanguage()? strings.account_type: ''}
                                                    >
                                                        {accountTypeList && accountTypeList.length > 0 ?
                                                            <>
                                                            {accountTypeList.map((type) => {
                                                                return(
                                                                    <option value={type.id}>{type.value}</option>
                                                                );
                                                            })}
                                                            </>
                                                            :null
                                                        }
                                                    </Select>
                                                    <FormHelperText>{this.state.errors.accountType}</FormHelperText>
                                                </FormControl>
                                            </Col>
                                        </Row>
                                    </Col>

                                    {this.state.accountHolderType == "Individual" ? 
                                        <>
                                            <Col className="form-item">
                                                <Row>
                                                    <Col className="text-item">
                                                        <TextField className="text-field" onChange={this.handleChange}
                                                            error={this.state.errors.firstName}
                                                            id="firstName"
                                                            name="firstName"
                                                            label={strings.getLanguage()? strings.first_name: ''}
                                                            type="text"
                                                            variant="outlined"
                                                            inputProps={{maxLength: 50}}
                                                            value={this.state.firstName}
                                                            helperText={this.state.errors.firstName}
                                                            />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col className="form-item">
                                                <Row>
                                                    <Col className="text-item">
                                                        <TextField className="text-field" onChange={this.handleChange}
                                                            error={this.state.errors.lastName}
                                                            id="lastName"
                                                            name="lastName"
                                                            label={strings.getLanguage()? strings.last_name: ''}
                                                            type="text"
                                                            variant="outlined"
                                                            value={this.state.lastName}
                                                            inputProps={{maxLength: 50}}
                                                            helperText={this.state.errors.lastName}
                                                            />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col className="form-item">
                                                <Row>
                                                    <Col className="text-item date-item">
                                                        {/* <TextField className="text-field" onChange={this.handleChange}
                                                            error={this.state.errors.dateOfBirth}
                                                            id="dateOfBirth"
                                                            name="dateOfBirth"
                                                            label={strings.getLanguage()? strings.date_of_birth: ''}
                                                            type="date"
                                                            variant="outlined"
                                                            format="mm/dd/yyyy"
                                                            value={this.state.dateOfBirth}
                                                            helperText={this.state.errors.dateOfBirth}
                                                        /> */}
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                            <DatePicker
                                                                disableFuture
                                                                openTo="year"
                                                                format="MM/dd/yyyy"
                                                                label="Date of birth"
                                                                views={["year", "month", "date"]}
                                                                value={this.state.dateOfBirth}
                                                                //defaultValue={null}
                                                                onChange={(e) => this.handleChange(e, 'dob')}
                                                                inputVariant="outlined"
                                                                autoOk
                                                                clearable="true"
                                                                variant="inline"
                                                                error={this.state.errors.dateOfBirth}
                                                                helperText={this.state.errors.dateOfBirth}
                                                                maxDate={this.getMaximumDate()}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </>
                                    : null}
                                    {this.state.accountHolderType == "Company" ?
                                        <Col className="form-item">
                                            <Row>
                                                <Col className="text-item">
                                                    <TextField className="text-field" onChange={this.handleChange}
                                                        error={this.state.errors.companyName}
                                                        id="companyName"
                                                        name="companyName"
                                                        label={strings.getLanguage()? strings.company_name: ''}
                                                        type="text"
                                                        variant="outlined"
                                                        value={this.state.companyName}
                                                        inputProps={{maxLength: 50}}
                                                        helperText={this.state.errors.companyName}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    :null}
                                    <Col className="form-item">
                                        <Row>
                                            <Col className="text-item">
                                                <TextField className="text-field" onChange={(e)=>this.handleChange(e, 'number')}
                                                    error={this.state.errors.accountNumber}
                                                    id="accountNumber"
                                                    name="accountNumber"
                                                    label={strings.getLanguage()? strings.account_number: ''}
                                                    type="text"
                                                    variant="outlined"
                                                    inputProps={{maxLength: 20}}
                                                    value={this.state.accountNumber}
                                                    helperText={this.state.errors.accountNumber}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col className="form-item">
                                        <Row>  
                                            <Col className="text-item">
                                                <TextField className="text-field" onChange={(e)=>this.handleChange(e, 'number')}
                                                    error={this.state.errors.routingNumber}
                                                    id="routingNumber"
                                                    name="routingNumber"
                                                    label={strings.getLanguage()? strings.routing_number: ''}
                                                    type="text"
                                                    variant="outlined"
                                                    inputProps={{maxLength: 9}}
                                                    value={this.state.routingNumber}
                                                    helperText={this.state.errors.routingNumber}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>

                                    {this.state.accountHolderType == "Company" ?
                                        <Col className="form-item">
                                            <Row>  
                                                <Col className="text-item">
                                                    <TextField className="text-field" onChange={(e)=>this.handleChange(e, 'number')}
                                                        error={this.state.errors.ein}
                                                        id="ein"
                                                        name="ein"
                                                        label={strings.getLanguage()? strings.ein_number: ''}
                                                        type="text"
                                                        variant="outlined"
                                                        inputProps={{maxLength: 9}}
                                                        value={this.state.ein}
                                                        helperText={this.state.errors.ein}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    :null}

                                    {this.state.accountHolderType == "Individual" ?
                                        <Col className="form-item">
                                            <Row>   
                                                <Col className="text-item">
                                                    <TextField className="text-field" onChange={(e)=>this.handleChange(e, 'number')}
                                                        error={this.state.errors.last4ssn}
                                                        id="last4ssn"
                                                        name="last4ssn"
                                                        label={strings.getLanguage()? strings.ssn_number: ''}
                                                        type="text"
                                                        variant="outlined"
                                                        inputProps={{maxLength: 4}}
                                                        value={this.state.last4ssn}
                                                        helperText={this.state.errors.last4ssn}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>                        
                                    :null}

                                    <Col className="form-item">
                                        <Row>
                                            <Col className="text-item">
                                                <FormControl variant="outlined" error={this.state.errors.countryCode}>
                                                    <InputLabel htmlFor="outlined-age-native-simple">{strings.getLanguage()? strings.country_code: <></>}</InputLabel>
                                                    <Select onChange={this.handleChange}
                                                        name="countryCode"
                                                        value={this.state.selectedCountry}
                                                        native
                                                        label={strings.getLanguage()? strings.country_code: ''}
                                                    >
                                                        {countryListOptions && countryListOptions.length > 0 ?
                                                            <>
                                                            {countryListOptions.map((type) => {
                                                                return(
                                                                    <option value={type.value}>{type.value}</option>
                                                                );
                                                            })}
                                                            </>
                                                            :null
                                                        }
                                                    </Select>
                                                    <FormHelperText>{this.state.errors.countryCode}</FormHelperText>
                                                </FormControl>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col className="form-item">
                                        <Row>
                                            <Col className="text-item">
                                                <TextField className="text-field" onChange={this.handleChange}
                                                    error={this.state.errors.businessWebsite}
                                                    id="businessWebsite"
                                                    name="businessWebsite"
                                                    label={strings.getLanguage()? strings.business_website: ''}
                                                    type="text"
                                                    variant="outlined"
                                                    inputProps={{maxLength: 100}}
                                                    value={this.state.businessWebsite}
                                                    helperText={this.state.errors.businessWebsite}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col className="form-item">
                                        <Row className="mobile-field">
                                            <Col md={3} className="text-item">
                                                {/* <TextField className="text-field" onChange={this.handleChange}
                                                    error={this.state.errors.code}
                                                    id="code"
                                                    name="code"
                                                    label={strings.getLanguage()? strings.code: ''}
                                                    type="text"
                                                    variant="outlined"
                                                    value={this.state.stdCode?.code}
                                                    helperText={this.state.errors.code}
                                                /> */}
                                                
                                                <FormControl variant="outlined" error={this.state.errors.code}>
                                                    <InputLabel htmlFor="outlined-age-native-simple">{strings.getLanguage() ? strings.code : <></>}</InputLabel>
                                                    <Select onChange={this.handleChange}
                                                        name="code"
                                                        value={this.state.stdCode?.code}
                                                        native
                                                        label={strings.getLanguage() ? strings.code : ''}
                                                    >
                                                        {countryListOptions && countryListOptions.length > 0 ?
                                                            <>
                                                                {countryListOptions.map((type) => {
                                                                    return (
                                                                        <option value={type.id}>{`+${type.id}`}</option>
                                                                    );
                                                                })}
                                                            </>
                                                            : null
                                                        }
                                                    </Select>
                                                    <FormHelperText>{this.state.errors.countryCode}</FormHelperText>
                                                </FormControl>
                                            </Col>
                                            <Col md={9} className="text-item">
                                                <TextField className="text-field" onChange={(e)=>this.handleChange(e, 'number')}
                                                    error={this.state.errors.phoneNumber}
                                                    id="phoneNumber"
                                                    name="phoneNumber"
                                                    label={this.state.accountHolderType == "Company" ? strings.mobile_number_optional : strings.mobile_number}
                                                    type="text"
                                                    variant="outlined"
                                                    inputProps={{maxLength: 10}}
                                                    value={this.state.phoneNumber}
                                                    helperText={this.state.errors.phoneNumber}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    
                                    {this.state.accountHolderType == "Company" 
                                    ?<Col className="form-item">
                                        <Row>
                                            <Col className="text-item">
                                                <TextField className="text-field" onChange={(e)=>this.handleChange(e, 'number')}
                                                    error={this.state.errors.vatNumber}
                                                    id="vatNumber"
                                                    name="vatNumber"
                                                    label={strings.getLanguage()? strings.vat_number: ''}
                                                    type="text"
                                                    variant="outlined"
                                                    inputProps={{maxLength: 6}}
                                                    value={this.state.vatNumber}
                                                    helperText={this.state.errors.vatNumber}
                                                />
                                            </Col>
                                        </Row>
                                    </Col> : null}

                                    {this.state.accountHolderType == "Individual" 
                                    ?   <Col className="form-item">
                                        <Row>
                                            <Col className="text-item">
                                                <TextField className="text-field" onChange={this.handleChange}
                                                    error={this.state.errors.email}
                                                    id="email"
                                                    name="email"
                                                    label={strings.getLanguage()? strings.email_optional: ''}
                                                    type="text"
                                                    variant="outlined"
                                                    inputProps={{maxLength: 50}}
                                                    value={this.state.email}
                                                    helperText={this.state.errors.email}
                                                />
                                            </Col>
                                        </Row>
                                    </Col> : null}

                                    <Col className="form-item">
                                        <Row>
                                            <Col className="text-item">
                                                <TextField className="text-field" onChange={this.handleChange}
                                                    error={this.state.errors.addressLineOne}
                                                    id="addressLineOne"
                                                    name="addressLineOne"
                                                    label={strings.getLanguage()? strings.address_line_one_optional: ''}
                                                    type="text"
                                                    variant="outlined"
                                                    inputProps={{maxLength: 100}}
                                                    value={this.state.addressLineOne}
                                                    helperText={this.state.errors.addressLineOne}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className="form-item">
                                        <Row>
                                            <Col className="text-item">
                                                <TextField className="text-field" onChange={this.handleChange}
                                                    error={this.state.errors.addressLineTwo}
                                                    id="addressLineTwo"
                                                    name="addressLineTwo"
                                                    label={strings.getLanguage()? strings.address_line_two_optional: ''}
                                                    type="text"
                                                    variant="outlined"
                                                    inputProps={{maxLength: 100}}
                                                    value={this.state.addressLineTwo}
                                                    helperText={this.state.errors.addressLineTwo}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className="form-item">
                                        <Row>
                                            <Col className="text-item">
                                                <TextField className="text-field" onChange={this.handleChange}
                                                    error={this.state.errors.city}
                                                    id="city"
                                                    name="city"
                                                    label={strings.getLanguage()? strings.city_optional: ''}
                                                    type="text"
                                                    variant="outlined"
                                                    inputProps={{maxLength: 50}}
                                                    value={this.state.city}
                                                    helperText={this.state.errors.city}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className="form-item">
                                        <Row>
                                            <Col className="text-item">
                                                <TextField className="text-field" onChange={this.handleChange}
                                                    error={this.state.errors.state}
                                                    id="state"
                                                    name="state"
                                                    label={strings.getLanguage()? strings.state_optional: ''}
                                                    type="text"
                                                    variant="outlined"
                                                    inputProps={{maxLength: 50}}
                                                    value={this.state.state}
                                                    helperText={this.state.errors.state}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className="form-item">
                                        <Row>
                                            <Col className="text-item">
                                                <TextField className="text-field" onChange={(e)=>this.handleChange(e, 'number')}
                                                    error={this.state.errors.zip}
                                                    id="zip"
                                                    name="zip"
                                                    label={strings.getLanguage()? strings.zip_optional: ''}
                                                    type="text"
                                                    variant="outlined"
                                                    inputProps={{maxLength: 10}}
                                                    value={this.state.zip}
                                                    helperText={this.state.errors.zip}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </form>
                        </div>
                    :   null}
                </div>
                {
                            confirmAlert(
                                strings.confirm_delete_bank,
                                'Yes',
                                'Cancel',
                                isConfirmAlert,
                                '',
                                () => this.onConfirmDelete(),
                                () => this.closeConfirmAlert()
                            )
                        }
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    authData: state.auth.authData,
});
export default connect(mapStateToProps, {})(withStyles( { withTheme: true })(AddBankDetails));