import IconButton from '@material-ui/core/IconButton';
import React, { Component } from 'react';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import {
	Container, DropdownItem, DropdownMenu, DropdownToggle, Nav, Navbar, NavItem,
	NavLink,
	UncontrolledDropdown
} from 'reactstrap';
import { Route, Link, Routes, useLocation } from 'react-router-dom';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import { ReactComponent as MenuIcon } from '../../assets/images/menu.svg';
import FirebaseImage from '../../components/firebase_fast_image_view';
import { data } from '../../utils/locales/data';
import './style.scss';
import MediaQuery from 'react-responsive'
import { FaAngleLeft, FaBars, FaPlus } from 'react-icons/fa';
import AddCard from "../../components/payment/add_card/index"
import Payment from '../../components/payment';
import { getAllCardListAPI } from '../../utils/api-helper';
import { useContext } from 'react';
import { DataContext } from '../../App';


let strings = new LocalizedStrings(data);
class TopBar extends Component {
	// static contextType =DataContext;
	constructor(props) {
		super(props)

		this.state = {
			pilotMenuList: props.pilotMenuList,
			companyMenuList: props.companyMenuList,
			open: false,
			openDrwerMobile: true,
			isAddCard: false,
		}
	}
	handleSignOut = () => {
		alert("signout")
	}

	onCloseModal = () => {
		this.setState({ isAddCard: false });

	}


	handleOpenMobileDrawer() {

		console.log("state", this.state.openDrwerMobile)
		if (this.state.openDrwerMobile) {
			this.props.pageState(true)
		}
		else {
			this.props.pageState(true)
		}

	}

	render() {
		console.log("aaa111==>", this.context)
		const { userData } = this.props;
		console.log("routeName", this.props.selectedMenu)
		return (
			<>

				<div className='NavBar'>

					<Navbar fixed="top" style={{ height: 50, boxShadow: '0 3px 15px rgba(0, 0, 0, 0.06)', backgroundColor: '#0f0643', padding: 0, }} >
						<Container style={{ padding: '0 15vw', maxWidth: '100%' }} >


							<MediaQuery maxWidth={480}>


								{this.props.selectedMenu == "Settings" &&
									<div style={{ justifyContent: "center", alignItems: "center", flexDirection: "row", display: "flex" }}>

										<div onClick={(e) => { this.handleOpenMobileDrawer(e); this.setState({ openDrwerMobile: !this.state.openDrwerMobile }) }} >
											<FaBars color="white" size={20} style={{ marginLeft: -20, marginBottom: 5 }} />
										</div>
										<div>
											<p style={{ color: "white", alignSelf: "center", marginTop: 10, marginLeft: 110, fontSize: 17 }}>Home</p>
										</div>
									</div>
								}

								{this.props.selectedMenu == "Subscription" &&
									<div style={{ justifyContent: "center", alignItems: "center", flexDirection: "row", display: "flex" }}>

										<div onClick={(e) => { this.handleOpenMobileDrawer(e); this.setState({ openDrwerMobile: !this.state.openDrwerMobile }) }} >
											<FaBars color="white" size={20} style={{ marginLeft: -20, marginBottom: 5 }} />
										</div>
										<div>
											<p style={{ color: "white", alignSelf: "center", marginTop: 10, marginLeft: 90, fontSize: 17 }}>Subscription</p>
										</div>
									</div>
								}

								{this.props.selectedMenu == "Payment" &&
									<div style={{ justifyContent: "center", alignItems: "center", flexDirection: "row", display: "flex" }}>

										<div onClick={(e) => { this.handleOpenMobileDrawer(e); this.setState({ openDrwerMobile: !this.state.openDrwerMobile }) }} >
											<FaBars color="white" size={20} style={{ marginLeft: -20, marginBottom: 5 }} />
										</div>

										<div>
											<p style={{ color: "white", alignSelf: "center", marginTop: 10, marginLeft: 100, fontSize: 17 }}>Payment</p>
										</div>
										{/* <div onClick={() => this.setState({ isAddCard: !this.state.isAddCard })}>
											<FaPlus color="white" size={20} style={{ marginLeft: 110, marginBottom: 5 }} />
											{this.state.isAddCard == true &&
												<>
													<AddCard modalStatus={this.state.isAddCard} onClose={this.onCloseModal} ></AddCard>
												</>
											}

										</div> */}
									</div>
								}

							</MediaQuery>



							<div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
								<div style={{ display: 'flex' }}>
									<div className="hamberger-button">
										<IconButton
											color="#FFF"
											aria-label="open drawer"
											edge="start"
											style={{ marginLeft: 0, color: 'white', padding: 0 }}
										>
											<MenuIcon
												// onMouseEnter={this.props.onHoverOpen} 
												onClick={this.props.handleDrawerOpen} />
										</IconButton>
									</div>
									<Logo className='top-bar-logo' />
								</div>
								<h6 style={{ marginTop: 20, marginLeft: 20 }}>{this.props.selectedHeader}</h6>
								<div className="right-section">
									<Nav>
										{/* {this.props?.role === 'PILOT' || this.props?.role === 'FLIGHT_ATTENDANT' ?
											(this.state.pilotMenuList.slice(0, 4).map(item =>
												<NavItem className={this.props.selectedMenu === item.name ? "selected-nav-item" : null}>
													<NavLink className='nav-link' href='#' onClick={() => this.props.onMenuSelect(item)}>
														<span>{item?.name}</span>
													</NavLink>
												</NavItem>
											))
											: (this.state.companyMenuList.slice(0, 4).map(item =>
												<NavItem className={this.props.selectedMenu === item.name ? "selected-nav-item" : null}>
													<NavLink className='nav-link' href='#' onClick={() => this.props.onMenuSelect(item)}>
														<span>{item?.name}</span>
													</NavLink>
												</NavItem>
											))
										} */}
										{/* <UncontrolledDropdown nav inNavbar>
											<DropdownToggle nav caret>
												{strings.more}
											</DropdownToggle>
											<DropdownMenu right>
												{this.props?.role === 'PILOT' || this.props?.role === 'FLIGHT_ATTENDANT' ?
													(this.state.pilotMenuList.slice(4, this.state.pilotMenuList?.length - 1).map(item =>
														<DropdownItem onClick={() => this.props.onMenuSelect(item)}>
															{item?.name}
														</DropdownItem>
													))

													: (this.state.companyMenuList.slice(4, this.state.companyMenuList?.length - 1).map(item =>
														<DropdownItem onClick={() => this.props.onMenuSelect(item)}>
															{item?.name}
														</DropdownItem>
													))
												}
												<DropdownItem divider />
												<DropdownItem onClick={() => this.props.onMenuSelect({ name: 'Logout' })}>
													{this.state.pilotMenuList[this.state.pilotMenuList?.length - 1].name}
												</DropdownItem>

											</DropdownMenu>
										</UncontrolledDropdown> */}
									</Nav>
									<div>
										{/* <Avatar alt="Remy Sharp" src={TempProfile} className="profile-avatar" /> */}
										{/* <FirebaseImage
											src={userData && userData?.profileImagePath ? userData?.profileImagePath : null}
											className={'profile-avatar'} /> */}
									</div>
								</div>
							</div>
						</Container>
					</Navbar>
				</div>


			</>
		);
	}
}

const mapStateToProps = (state) => ({
	authData: state.auth.authData,
	userData: state.auth.loginUserData
});

export default connect(mapStateToProps, {})(TopBar);