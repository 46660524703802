import React from 'react';
import VerificationCodeComponent from '../components/user_registration/verification_code';

const VerificationCode = props => {
    return (
        <div>
            <VerificationCodeComponent {...props}/>
        </div>
    );
}
export default VerificationCode;