import React, { useState } from "react";
import "./firebaseImage.scss";
import defaultUserProfilePic from "../../assets/images/default_profile_pic.svg";
import { getMediaUrl } from "../../utils/firebase_helper/auth";

function FirebaseImage(props) {
  const [path, setPath] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const getPath = () => {
    setIsLoading(true);
    getMediaUrl(props.src)
      .then((url) => {
        setPath(url);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  if (props.src && !isLoading && !path) {
    getPath();
  }

  return (
    <img
      className={`profile-pic  ${props.className}`}
      alt="profile-pic"
      src={path ? path : defaultUserProfilePic}
    />
  );
}



export default FirebaseImage;
