import React, { Component } from 'react';
import LocalizedStrings from 'react-localization';
import { data } from '../../../utils/locales/data';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { changePasswordAPI, loginAPI, OAUTH2_CLIENT_ID } from '../../../utils/api-helper';
import { validateEqualInputs, validatePassword } from '../../../utils/input-validation';
import { showToast, showSpinner } from '../../../utils/common_helper';
import clsx from 'clsx';
import { withStyles } from "@material-ui/core/styles";
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import './style.scss';
import closeIcon from '../../../assets/images/modal_cross.svg'
let strings = new LocalizedStrings(data);

const styles = (theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    },
    textField: {
        width: '100%',
        margin: '20px 0'
    },
});

class ChangePassword extends Component {

    constructor(props) {
        super(props)

        this.state = {
            oldPassword: '',
            showOldPassword: false,
            newPassword: '',
            showNewPassword: false,
            confirmPassword: '',
            showConfirmPassword: false,
            modal: false,
            password: '',

        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.modal !== nextProps.modalStatus) {
            return {
                modal: nextProps.modalStatus,
              };
        }
    }

    oldPasswordValidation = (pwd) => {
        this.setState({ oldPassword: pwd?.trim(), oldPasswordError: !pwd?.trim().length });
    };

    newPasswordValidation = (pwd) => {
        this.setState({ newPassword: pwd?.trim(), newPasswordError: !(validatePassword(pwd?.trim()) && !validateEqualInputs(this.state.oldPassword, pwd)) });
    };

    confirmPasswordValidation = (pwd) => {
        this.setState({ confirmPasswordError: !validateEqualInputs(this.state.newPassword, pwd) });
    };

    handleSubmit = () => {
        this.oldPasswordValidation(this.state.oldPassword);
        this.newPasswordValidation(this.state.newPassword);
        this.confirmPasswordValidation(this.state.confirmPassword);
        this.validateAllFields();
    };

    validateAllFields = () => {
        if (this.checkFields()) {
            this.setState({ showLoader: true });
            let body = {
                "newPassword": this.state.newPassword,
                "oldPassword": this.state.oldPassword
            }
                changePasswordAPI(body).then(response => {
                    if (response?.message) {
                        showToast(response?.message, "success");
                    }
                    this.setState({ showLoader: false });
                    this.toggle();
                }).catch(error => {
                    if (error?.data?.message) {
                        showToast(error?.data?.message, "error");
                    }
                    this.setState({ showLoader: false });
                });
        }
    };

    checkFields = () => {
        return (this.state.oldPassword?.trim().length
            && (validatePassword(this.state.newPassword?.trim()) && !validateEqualInputs(this.state.oldPassword, this.state.newPassword))
            && validateEqualInputs(this.state.newPassword, this.state.confirmPassword));
    };

    handleChange = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
        if ([prop] == 'oldPassword') {
            this.oldPasswordValidation(event.target.value);
        } else if ([prop] == 'newPassword') {
            this.newPasswordValidation(event.target.value);
        } else if ([prop] == 'confirmPassword') {
            this.confirmPasswordValidation(event.target.value);
        }
    };

    handleClickShowPassword = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    };

    handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    toggle = () => {
        if (this.state.modal) {
            this.clearStates();
        }
        if(this.state.modal === true){
            this.props.onClose();
        }
        this.setState({ modal: !this.state.modal });
    }

    clearStates = () => {
        this.setState({
            oldPassword: '',
            showOldPassword: false,
            newPassword: '',
            showNewPassword: false,
            confirmPassword: '',
            showConfirmPassword: false,
            modal: false,
            password: '',
            oldPasswordError: false,
            newPasswordError: false,
            confirmPasswordError: false,
        });
    }

    errorView = (msg) => {
        return (
            <p className="error-msg-style">{msg}</p>
        )
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                {showSpinner(this.state.showLoader)}
                {/* <Button color="danger" onClick={this.toggle}>Change Password</Button> */}
                <Modal isOpen={this.state.modal} toggle={this.toggle} centered backdrop='static' className="change-pwd-modal" >
                    {/* <ModalHeader className="change-pwd-modal-header" toggle={this.toggle}>Change Password</ModalHeader> */}
                    <ModalBody>
                        <div className="modal-custom-header">
                            <div className="heading-text">Change Password</div>
                            <div className="close-icon" onClick={this.toggle}>
                                <img src={closeIcon} alt="close-icon" />
                            </div>
                        </div>
                        <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
                            <InputLabel className={this.state.oldPasswordError ? "change-pwd-input-label error-msg-color" : "change-pwd-input-label"} htmlFor="old-password" >Old Password</InputLabel>
                            <OutlinedInput
                                id="old-password"
                                type={this.state.showOldPassword ? 'text' : 'password'}
                                value={this.state.oldPassword}
                                size="small"
                                onChange={this.handleChange('oldPassword')}
                                className="change-pwd-input-top change-pwd-input-val"
                                error={this.state.oldPasswordError}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => { this.setState({ showOldPassword: !this.state.showOldPassword }) }}
                                            onMouseDown={this.handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {this.state.showOldPassword ?
                                                <VisibilityIcon />
                                                :
                                                <VisibilityOffIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                labelWidth={70}
                            />
                        </FormControl>
                        {this.state.oldPasswordError ? this.errorView(strings.error_old_password) : null}
                        <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
                            <InputLabel className={this.state.newPasswordError ? "change-pwd-input-label error-msg-color" : "change-pwd-input-label"} htmlFor="new-password">New Password</InputLabel>
                            <OutlinedInput
                                id="new-password"
                                type={this.state.showNewPassword ? 'text' : 'password'}
                                value={this.state.newPassword}
                                size="small"
                                onChange={this.handleChange('newPassword')}
                                className="change-pwd-input-top change-pwd-input-val"
                                error={this.state.newPasswordError}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => { this.setState({ showNewPassword: !this.state.showNewPassword }) }}
                                            onMouseDown={this.handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {this.state.showNewPassword ?
                                                <VisibilityIcon />
                                                :
                                                <VisibilityOffIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                labelWidth={70}
                            />
                        </FormControl>
                        {this.state.newPasswordError ? this.errorView(!validatePassword(this.state.newPassword?.trim()) ? strings.error_valid_password : strings.error_old_new_password) : null}
                        <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
                            <InputLabel className={this.state.confirmPasswordError ? "change-pwd-input-label error-msg-color" : "change-pwd-input-label"} htmlFor="confirm-password">Confirm Password</InputLabel>
                            <OutlinedInput
                                id="confirm-password"
                                type={this.state.showConfirmPassword ? 'text' : 'password'}
                                value={this.state.confirmPassword}
                                size="small"
                                onChange={this.handleChange('confirmPassword')}
                                className="change-pwd-input-top change-pwd-input-val"
                                error={this.state.confirmPasswordError}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => { this.setState({ showConfirmPassword: !this.state.showConfirmPassword }) }}
                                            onMouseDown={this.handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {this.state.showConfirmPassword ?
                                                <VisibilityIcon height={20} width={20} />
                                                :
                                                <VisibilityOffIcon height={20} width={50} />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                labelWidth={70}
                            />
                        </FormControl>
                        {this.state.confirmPasswordError ? this.errorView(strings.error_password_match) : null}
                    </ModalBody>
                    <ModalFooter>
                        <Button className="negative-btn" onClick={this.toggle}>CANCEL</Button>{' '}
                        <Button className="positive-btn" onClick={this.handleSubmit}>UPDATE</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(ChangePassword)