import React from 'react';
import { toast } from 'react-toastify';
import LoadingSpinner from '../../components/Loading-Spinner';
import ConfirmAlert from '../../components/Confirm-Alert';

export function showToast(message, type) {
    if (type) {
      if (type === "info") {
        toast.info(message,  {autoClose: 3000, closeOnClick: true});
      } else if (type === "success") {
        toast.success(message);
      } else if (type === "warn") {
        toast.warn(message);
      } else if (type === "error") {
        toast.error(message, {position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,});
      } else if (type === "dark") {
        toast.dark(message);
      }
    } else {
      toast(message);
    }
  }

  export function showSpinner(spinnerFlag) {
    return (
      spinnerFlag && spinnerFlag === true ? <LoadingSpinner /> : null
    )
  }

  export function confirmAlert(message, positiveBTN, negativeBTN, isOpen, customClass, positiveCallback, negativeCallback ) {
      return <ConfirmAlert
        confirmMsg={message}
        positiveBTN={positiveBTN}
        negativeBTN={negativeBTN}
        isOpen={isOpen}
        customClass={customClass}
        positiveCallback={() => positiveCallback()}
        negativeCallback={() => negativeCallback()} />
  }

  export function capitalizeText(text) {
    let str = text ? text : '';
    str = str.replace('_', ' ');
    str = str.toLowerCase();
    const arr = str.split(" ");
  
    for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  
    }
    const finalSTR = arr.join(" ");
    return finalSTR;
  }