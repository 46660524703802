import React, { Component } from 'react';
import { connect } from 'react-redux';
import LocalizedStrings from 'react-localization';
import {data} from '../../utils/locales/data';
import { Container } from 'reactstrap';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ArrowIcon from '../../assets/images/settings_arrow.svg';
import './settings.scss';

import ChangePassword from '../../screens/settings/change_password'

let strings = new LocalizedStrings(data);

class SettingsComponent extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            checkedA: false,
            ischangePWD: false
        }        
    }

    handleNotificationChange = (event) => {
        this.setState({checkedA: event.target.checked})
    }

    handleSettingsClick = (item) => {
        if(item === 'Change Password'){
            this.setState({ischangePWD: true})
        }
    }

    onCloseModal = () => {
        this.setState({ischangePWD: false});
    }

    NotificationSwitch = withStyles((theme) => ({
        root: {
            width: 32,
            height: 20,
            padding: 0,
            margin: theme.spacing(1),
        },
        switchBase: {
            padding: 1,
            transform: 'translateX(24px)',
            '&$checked': {
                transform: 'translateX(36px)',
                color: theme.palette.common.white,
                '& + $track': {
                    backgroundColor: '#4cd964 !important',
                    opacity: 1,
                    border: 'none',
                },
            },
            '&$focusVisible $thumb': {
                color: '#4cd964 !important',
                border: '6px solid #fff',
            },
        },
        thumb: {
            width: 18,
            height: 18,
        },
        track: {
            borderRadius: 20 / 2,
            border: `1px solid ${theme.palette.grey[400]}`,
            backgroundColor: theme.palette.grey[50],
            opacity: 1,
            transition: theme.transitions.create(['background-color', 'border']),
        },
        checked: {},
        focusVisible: {},
    }))(({ classes, ...props }) => {
        return (
            <Switch
                focusVisibleClassName={classes.focusVisible}
                disableRipple
                classes={{
                    root: classes.root,
                    switchBase: classes.switchBase,
                    thumb: classes.thumb,
                    track: classes.track,
                    checked: classes.checked,
                }}
                {...props}
                checked={this.state.checkedA}
                onChange={this.handleNotificationChange}
                name="checkedA"
            />
        );
    });

    render() {
        const { ischangePWD } = this.state;
        return(
            <div className="settings-wrapper">
                <div className="heading-container">
                    {strings.setting}
                </div>

                <div className="settings-list-container">
                    {/* <div className="setting-item">
                        <div className="item-name">{strings.notifications}</div>
                        <div className="item-action">
                            <this.NotificationSwitch />
                        </div>
                    </div> */}

                    <div
                        className="setting-item" 
                        onClick={() => this.handleSettingsClick('Change Password')}>
                        <div className="item-name">{strings.change_password}</div>
                        <div className="item-action">
                            <IconButton
                                color="#FFF"
                                aria-label="open drawer"
                                edge="start"
                                style={{ marginLeft: 0, color: 'white' }}
                            >
                                <img src={ArrowIcon} alt="arrow-icon" />
                            </IconButton>
                            <ChangePassword modalStatus={ischangePWD} onClose={this.onCloseModal} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

/* *Set data to store.* */
const mapDispatchToProps = (dispatch) => {}
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsComponent)