import React, { Component } from 'react';
import { data } from '../../../utils/locales/data';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import {Row, Col, Button} from 'reactstrap';
import TextField from '@material-ui/core/TextField';
import '../auth.scss';
import PasswordIcon from '../../../assets/password.png';
import { validateEqualInputs, validatePassword } from '../../../utils/input-validation';
import { resetPasswordAPI, setAuthToken } from '../../../utils/api-helper';
import { showToast, showSpinner } from '../../../utils/common_helper';
import { Link, withRouter } from 'react-router-dom';

let strings = new LocalizedStrings(data);
class ResetPassword extends Component {
    constructor(props) {
        super(props)

        this.state = {
            newPassword: '',
            confirmPassword: '',
            showLoader: false,
            showPassword: false,
            showConfirmPassword: false,
            isSuccess: false,
            errors: {},
            isSubmitted: false
        }
    }

    componentWillUnmount = () => {
        setAuthToken();
    }

    validate = () =>  {
	    let errors = {};
        let IsValid = true;

        if (this.state["newPassword"] != "" && !validatePassword(this.state.newPassword.trim())) {
            IsValid = false;
            errors["newPassword"] = strings.error_valid_password;
        }else if (!this.state["newPassword"]) {
            IsValid = false;

            errors["newPassword"] = strings.error_password;
        }

        if ( !validateEqualInputs(this.state.newPassword, this.state.confirmPassword)) {
			IsValid = false;
			errors["confirmPassword"] = strings.error_password_match;
		}

        this.setState({ errors: errors }, () => {
            console.log('-------------------------------------valid-------',IsValid)
            return IsValid;
        });
        // return IsValid;

	}
    
    handleSubmit = (e) => {
        this.setState({isSubmitted: true});
        e.preventDefault();
        
        
        if (this.validate) {
            console.log('-------------------------------------------submit-------')
            this.setState({ showLoader: true });
            let body = {
                authPassword: this.state.newPassword,
            }
            resetPasswordAPI(body).then(response => {
                this.setState({ showLoader: false });
                if (response?.message) {
                    showToast(response?.message, "success");
                    this.props.history.push("/")
                }
            }).catch(error => {
                this.setState({ showLoader: false });
                if (error?.data?.message) {
                    showToast(error?.data?.message, "error");
                }
            });
        }
    };

    handleChange = (e) => {
	    let errors = {};
        let IsValid = true;
        this.setState({ [e.target.name]: e.target.value });
        if(this.state.isSubmitted){
            if (e.target.name == "newPassword"){
                if (this.state["newPassword"] != "" && !validatePassword(this.state.newPassword.trim())) {
                    IsValid = false;
                    errors["newPassword"] = strings.error_valid_password;
                }else if (!this.state["newPassword"]) {
                    errors["newPassword"] = strings.error_password;
                }
            }else if (e.target.name == "confirmPassword"){
                if ( !validateEqualInputs(this.state.newPassword, e.target.value)) {
                    IsValid = false;
                    errors["confirmPassword"] = strings.error_password_match;
                }
            }
            this.setState({ errors: errors });
            return IsValid;
        }   
    }

    toggle = () => {
        this.setState({modal: !this.state.modal})
    }

    pwdToggle = () =>{
        this.setState({showPassword: !this.state.showPassword});
    }

    cpwdToggle = () =>{
        this.setState({showConfirmPassword: !this.state.showConfirmPassword});
    }

    render() {
        return (
            <div className="common-auth-wrapper">
                {showSpinner(this.state.showLoader)}
               <Row>
                    <Col className="bg-image" >
                        <div className="logo"></div>
                    </Col>
                    <Col className="common-reg-wrapper">
                        <div className="form-wrapper">
                            {strings.getLanguage() ?
                                <>
                                    <p className="title">{strings.reset_password}</p>
                                    <p className="subtext">{strings.resend_code_text}</p>
                                </>
                                : <></>
                            }
                            <form autoComplete="off" onSubmit={this.handleSubmit}>
                                <div className="text-item">
                                    <div className="text-icon">
                                        <img src={PasswordIcon} />
                                    </div>
                                    <TextField className="text-field" onChange={this.handleChange}
                                        error={this.state.errors.newPassword}
                                        id="newPassword"
                                        name="newPassword"
                                        value={this.state.password}
                                        label={strings.getLanguage()? strings.new_password: ''}
                                        type={this.state.showPassword ? 'text' : 'password'}
                                        variant="outlined"
                                        helperText={this.state.errors.newPassword}
                                    />
                                     <span className={this.state.showPassword ? 'show-pwd' : 'hide-pwd'} onClick={() => this.pwdToggle()}></span>
                                </div>
                                <div className="text-item">
                                    <div className="text-icon">
                                        <img src={PasswordIcon} />
                                    </div>
                                    <TextField className="text-field" onChange={this.handleChange}
                                        error={this.state.errors.confirmPassword}
                                        id="confirmPassword"
                                        value={this.state.confirmPassword}
                                        name="confirmPassword"
                                        label={strings.getLanguage()? strings.confirm_password: ''}
                                        type={this.state.showConfirmPassword ? 'text' : 'password'}
                                        variant="outlined"
                                        helperText={this.state.errors.confirmPassword}
                                    />
                                     <span className={this.state.showConfirmPassword ? 'show-pwd' : 'hide-pwd'} onClick={() => this.cpwdToggle()}></span>
                                </div>
                                <div className="text-item">
                                    <Button
                                    type="submit"
                                    // disabled={!(this.state.newPassword && this.state.confirmPassword)}
                                    className="common-button signup-button">{strings.getLanguage() ?
                                        strings.reset: ''}</Button>{' '}
                                        <p className="subtext">{strings.getLanguage()? strings.backto_link_text: ''}
                                            &nbsp;<Link className="link-text" to="/">{strings.getLanguage()? strings.login: ''}</Link>
                                        </p>
                                </div>
                            </form>
                        </div>
                    </Col>
                </Row>
             
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
});
export default connect(mapStateToProps)(withRouter(ResetPassword))