import React, { Component } from "react";
import LocalizedStrings from "react-localization";
import { data } from "../../../utils/locales/data";
import RadioOff from "../../../assets/radio_off.png";
import RadioOn from "../../../assets/radio_on.png";
import DotIcon from "../../../assets/dot.png";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from "reactstrap";
import { addNewCardAPI } from "../../../utils/api-helper";
import { showToast, showSpinner } from "../../../utils/common_helper";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import "./style.scss";
import closeIcon from "../../../assets/images/modal_cross.svg";
import PaymentCheckIcon from '../../../assets/images/payment_check.svg';
import {
  saveCardDetails,
  initializeStripe,
} from "../../../utils/stripe-helper";
let strings = new LocalizedStrings(data);

const styles = (theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "100%",
    margin: "8px 0px 23px",
  },
});

class ConfirmModel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cardNumber: "",
      cardHolderName: "",
      expiryMonth: "",
      expiryYear: "",
      cvv: "",
      cardNumberError: false,
      cardHolderNameError: false,
      expiryMonthError: false,
      expiryYearError: false,
      cvvError: false,
      isSubmitted: false,
      proceedData: [],
      set_auto_renewal: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // console.log('--------------------------------props',nextProps)
    if (prevState.modal !== nextProps.modalStatus) {
      return {
        modal: nextProps.modalStatus,
      };
    }
  }

  componentDidMount = () => {
    initializeStripe();
  };

  handleSubmit = () => {
    const { transaction_id } = this.props;
    // this.setState({ isSubmitted: true });
    this.props.onConfirm(transaction_id);
  };
  handleProceed = () => {
    let { data } = this.props;
    if (this.state?.set_auto_renewal) {
      this.setState(
        { proceedData: { ...data, isAutoRenewal: true } },
        () => {
          this.props.onConfirm(this.state.proceedData);
        }
      );
    } else {
      this.setState({ proceedData: { ...data } }, () => {
        this.props.onConfirm(this.state.proceedData);
      });
    }
  };

  toggle = () => {
    this.setState({ modal: !this.state.modal });

    this.props.onClose();
  };

  clearStates = () => {
    this.setState({
      cardNumber: "",
      cardHolderName: "",
      expiryMonth: "",
      expiryYear: "",
      cvv: "",
      cardNumberError: false,
      cardHolderNameError: false,
      expiryMonthError: false,
      expiryYearError: false,
      cvvError: false,
    });
  };

  handleError = (error) => {
    this.setState({ showLoader: false }, () => {
      if (error?.data?.message) {
        showToast(error?.data?.message, "error");
      } else if (error.message) {
        showToast(error.message, "error");
      }
    });
  };

  render() {
    const {
      classes,
      modelTitle,
      alertMessage,
      message,
      title,
      planAmount,
      data,
      button_text,
      payment_success,
      alert,
    } = this.props;
    console.log("----------------------------------------data-----", data);
    let months = data?.duration;
    return (
      <div>
        {showSpinner(this.state.showLoader)}
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          centered
          backdrop="static"
          className="change-pwd-modal"
        >
          <ModalBody>
            <div className="modal-custom-header" style={payment_success ? { border: 'none' } : null}>
              <div className="heading-text">{modelTitle}</div>
              <div className="close-icon" onClick={this.toggle}>
                <img src={closeIcon} alt="close-icon" />

              </div>
            </div>
            {payment_success ? <div >
              <div>
                <div className="payment-success-model">
                  <img src={PaymentCheckIcon} />
                </div>
                <div className="payment-success-message-box">
                  <h3 className="payment-success-title">{title}</h3>
                  <label className="payment-success-message">{'Your payment of '} <span className="plan-amount">{'$'}{planAmount + ''}</span> {'  was successfully '}</label>
                    <label>completed</label>
                </div>
              </div>
            </div> : null}

            <div className="alert-message-box">
              <div>{message && <p className="message">{message}</p>}</div>
              <div>
                <span className="alert-message">{alertMessage}</span>
              </div>
              {data ? (

                <>
                  <label className="price">
                    {"$" + data.price}{" "}
                    <span className="duration">
                      {'  /  '} {months == 6 ? months : ''}<span>{months == 6 ? '  months' : months == 12 ? '  year' : '  month'}</span>
                    </span>{" "}
                  </label>

                  <div className="benifitList">
                    <span className="benefit">{strings.benefits}</span>
                    <div className="grid-box">
                      <div className="grid">
                        {data?.numberOfAircrafts ? (
                          <div>
                            <label className="benefit-data">
                              <img src={DotIcon} />
                              <span className="grid-item">
                                {strings.no_of_aircrafts} {"  "}{" "}
                                <span className="benefit-counters">
                                  {data.numberOfAircrafts}{" "}
                                </span>
                              </span>
                            </label>
                          </div>
                        ) : null}
                        {data?.numberOfPilot ? (
                          <div>
                            <label className="benefit-data">
                              <img src={DotIcon} />
                              <span className="grid-item">
                                {strings.no_of_pilot} {"  "}
                                <span className="benefit-counters">
                                  {" "}
                                  {data.numberOfPilot}{"    "}
                                </span>
                              </span>
                            </label>
                          </div>
                        ) : null}
                      </div>
                      <div className="grid">
                        {data?.numberOfAdminAndManagers ? (
                          <div style={{marginRight: '5px'}}>
                            <label className="benefit-data">
                              <img src={DotIcon} />
                              <span className="grid-item">
                                {strings.no_of_general} {"  "}{" "}
                                <span className="benefit-counters">
                                  {data.numberOfAdminAndManagers}{" "}
                                </span>
                              </span>
                            </label>
                          </div>
                        ) : null}
                        {data?.numberOfAttendants ? (
                          <div>
                            <label className="benefit-data">
                              <img src={DotIcon} />
                              <span className="grid-item">
                                {strings.no_of_attendent} {"  "}
                                <span className="benefit-counters">
                                  {" "}
                                  {data.numberOfAttendants}
                                </span>{" "}
                              </span>
                            </label>
                          </div>
                        ) : null}
                        {data?.planType === "Job Post" ? (
                          <label className="benefit-data">
                            <img src={DotIcon} />
                            <span className="grid-item">
                              {strings.unlimited_job_posts}
                            </span>
                          </label>
                        ) : null}
                      </div>

                    </div>
                  </div>

                  {data.plan_details ? (
                    <div>
                      <label className="benefit">
                        {strings.plan_details}
                      </label>
                      <div>
                        <span className="benefit-data">
                          {data.plan_details}
                        </span>
                      </div>
                    </div>
                  ) : null}
                  <div className="auto-renewal-box">
                    {this.state.set_auto_renewal ? (
                      <img
                        src={RadioOn}
                        className="radio-btn"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          this.setState({ set_auto_renewal: false });
                        }}
                        name="auto-renewal"
                      />
                    ) : (
                      <img
                        src={RadioOff}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          this.setState({ set_auto_renewal: true });
                        }}
                      />
                    )}

                    <div className="auto-renewal-text">
                      <label>{strings.set_auto_renewal}</label>
                    </div>
                  </div>
                </>
              ) : null}
            </div>

          </ModalBody>
          <ModalFooter>
            {data || alert ? (
              <Button
                className="positive-btn"
                onClick={alert ? this.toggle : this.handleProceed}
              >
                {button_text}
              </Button>
            ) : (
              <>
                <Button className="negative-btn" onClick={this.toggle}>
                  {strings.no}
                </Button>{" "}
                <Button className="positive-btn" onClick={this.handleSubmit}>
                  {strings.yes}
                </Button>{" "}
              </>
            )}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ConfirmModel);
