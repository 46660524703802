import { AUTH_DATA, USER_DATA, LOGIN_USER_DATA } from "./types";

export const saveAuthData = (data) => {
  return (dispatch) => {
    dispatch({ type: AUTH_DATA, payload: data });
  };
};

export const saveLoginUserData = (data) => {
  return (dispatch) => {
    dispatch({ type: LOGIN_USER_DATA, payload: data });
  };
};

export const saveUserData = (data) => {
  return (dispatch) => {
    dispatch({ type: USER_DATA, payload: data });
  };
};