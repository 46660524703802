import React, { Component } from 'react';
import LocalizedStrings from 'react-localization';
import { data } from '../../utils/locales/data';
import { connect } from 'react-redux';

let strings = new LocalizedStrings(data);
class Home extends Component {
    constructor(props) {
        super(props)

        this.state = {
            language: this.props?.language ? this.props.language : 'en',
        }
    }

    render() {
        return (
            <div className="App" style={{width:'100%', height: '100%'}}>
                    {strings.getLanguage() ?
                        <p>
                            {/* {strings.success} */}
                        </p>
                        : <></>
                    }
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    language: state.language,
});
export default connect(mapStateToProps)(Home)