import React, { Component } from 'react';
import LocalizedStrings from 'react-localization';
import { data } from '../../../utils/locales/data';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from 'reactstrap';
import { addNewCardAPI } from '../../../utils/api-helper';
import { showToast, showSpinner } from '../../../utils/common_helper';
import clsx from 'clsx';
import { withStyles } from "@material-ui/core/styles";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import './style.scss';
import closeIcon from '../../../assets/images/modal_cross.svg'
import { saveCardDetails, initializeStripe } from '../../../utils/stripe-helper';
import MediaQuery from 'react-responsive'

let strings = new LocalizedStrings(data);

const styles = (theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    },
    textField: {
        width: '100%',
        // margin: '8px 0px 23px'
    },
});

class AddCard extends Component {

    constructor(props) {
        super(props)

        this.state = {
            cardNumber: "",
            cardHolderName: "",
            expiryMonth: "",
            expiryYear: "",
            cvv: "",
            cardNumberError: false,
            cardHolderNameError: false,
            expiryMonthError: false,
            expiryYearError: false,
            cvvError: false,
            isSubmitted: false
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.modal !== nextProps.modalStatus) {
            return {
                modal: nextProps.modalStatus,
            };
        }
    }

    componentDidMount = () => {
        initializeStripe();
    }

    cardNameHolderValidation = (value) => {
        if (value) {
            this.setState({
                cardHolderNameError: (value.trim() && value.trim().length > 0 ? false : true)
            });
        } else {
            this.setState({
                cardHolderNameError: (this.state.cardHolderName.trim() && this.state.cardHolderName.trim().length > 0 ? false : true)
            });
        }
    }

    expiryMonthValidation = (value) => {
        if (value) {
            this.setState({
                expiryMonthError: (value.trim() && value.trim().length >= 2 ? false : true)
            });
        } else {
            this.setState({
                expiryMonthError: (this.state.expiryMonth.trim() && this.state.expiryMonth.trim().length >= 2 ? false : true)
            });
        }
    }

    expiryYearValidation = (value) => {
        if (value) {
            this.setState({
                expiryYearError: (value.trim() && value.trim().length >= 2 ? false : true)
            });
        } else {
            this.setState({
                expiryYearError: (this.state.expiryYear.trim() && this.state.expiryYear.trim().length >= 2 ? false : true)
            });
        }
    }

    cvvValidation = (value) => {
        if (value) {
            this.setState({
                cvvError: (value && value.trim() == "" ? false : value.trim().length >= 3 ? false : true)
            });
        } else {
            this.setState({
                cvvError: (this.state.cvv && this.state.cvv.trim() == "" ? false : this.state.cvv.trim().length >= 3 ? false : true)
            });
        }
    }

    cardNumberValidation = (value) => {
        if (value) {
            this.setState({
                cardNumberError: (value.trim() && value.trim().length >= 17 ? false : true)
            });
        } else {
            this.setState({
                cardNumberError: (this.state.cardNumber.trim() && this.state.cardNumber.trim().length >= 17 ? false : true)
            });
        }
    }

    cardNumberError() {
        if (!this.state.cardNumber.trim()) {
            return this.errorView(strings.error_empty_card_number);
        } else if (this.state.cardNumber.trim().length >= 17 || this.state.cardNumber.trim().length < 14) {
            return this.errorView(strings.error_invalid_card_number);
        }
    }

    cardHolderNameError() {
        return this.errorView(strings.error_invalid_holder_name);
    }

    expiryMonthError() {
        return this.errorView(strings.error_empty_expiry_month);
    }

    expiryYearError() {
        return this.errorView(strings.error_empty_expiry_year);
    }

    cvvError() {
        if (!this.state.cvv.trim()) {
            return this.errorView(strings.error_empty_cvv);
        } else if (this.state.cvv.trim().length < 3) {
            return this.errorView(strings.error_invalid_cvv);
        }
    }

    handleSubmit = () => {
        this.setState({ isSubmitted: true });
        this.cardNameHolderValidation(this.state.cardHolderName);
        this.cardNumberValidation(this.state.cardNumber);
        this.expiryMonthValidation(this.state.expiryMonth);
        this.expiryYearValidation(this.state.expiryYear);
        this.cvvValidation(this.state.cvv);
        this.validateFormData();
    };

    checkFields = () => {
        let isError = false;
        if (this.state.cardNumber.trim() && this.state.cardNumber.trim().length >= 17 ? false : true) {
            isError = true;
        }

        if (this.state.expiryMonth.trim() && this.state.expiryMonth.trim().length >= 2) {
            isError = true;
        }

        if (this.state.expiryYear.trim() && this.state.expiryYear.trim().length >= 2) {
            isError = true;
        }

        if (this.state.cvv.trim() == "" ? false : this.state.cvv.trim().length >= 3) {
            isError = true;
        }

        return !isError;
    }

    validateFormData = () => {
        this.state.cardNumberError = true;
        this.state.expiryMonthError = true;
        this.state.expiryYearError = true;
        this.state.cvvError = true;
        this.cardNumberValidation();
        this.expiryMonthValidation();
        this.expiryYearValidation();
        this.cvvValidation();
        if ((this.state.cardNumber.trim() && this.state.cardNumber.trim().length >= 17 ? false : true) ||
            (this.state.expiryMonth.trim() && this.state.expiryMonth.trim().length >= 1 && +this.state.expiryMonth != 0 ? false : true) ||
            (this.state.expiryYear.trim() && this.state.expiryYear.trim().length >= 2 ? false : true) ||
            (this.state.cvv.trim().length >= 3 ? false : true)) {
            return false
        }
        return true;
    }

    handleChange = (prop) => (event) => {

        if ([prop] == 'cardHolderName') {
            this.setState({ [prop]: event.target.value });
            // this.cardNameHolderValidation(event.target.value);
        } else if ([prop] == 'cardNumber') {
            this.setState({ [prop]: this.formatCardNumber(event.target.value) });
            // this.cardNumberValidation(this.formatCardNumber(event.target.value));

        } else if ([prop] == 'expiryMonth') {
            if ((event.target.value.length <= 2 && this.checkNumbersOnly(event.target.value)) || event.target.value === '') {
                this.setState({ [prop]: event.target.value });
                // this.expiryMonthValidation(event.target.value);
            }
        } else if ([prop] == 'expiryYear') {
            if ((event.target.value.length <= 2 && this.checkNumbersOnly(event.target.value)) || event.target.value === '') {
                this.setState({ [prop]: event.target.value });
                // this.expiryYearValidation(event.target.value);
            }
        } else if ([prop] == 'cvv') {
            if ((event.target.value.length <= 3 && this.checkNumbersOnly(event.target.value)) || event.target.value === '') {
                this.setState({ [prop]: event.target.value });
                // this.cvvValidation(event.target.value);
            }
        }

        if (this.state.isSubmitted) {
            if ([prop] == 'cardHolderName') {
                this.cardNameHolderValidation(event.target.value);
            } else if ([prop] == 'cardNumber') {
                this.cardNumberValidation(this.formatCardNumber(event.target.value));

            } else if ([prop] == 'expiryMonth') {
                if ((event.target.value.length <= 2 && this.checkNumbersOnly(event.target.value)) || event.target.value === '') {
                    this.expiryMonthValidation(event.target.value);
                }
            } else if ([prop] == 'expiryYear') {
                if ((event.target.value.length <= 2 && this.checkNumbersOnly(event.target.value)) || event.target.value === '') {
                    this.expiryYearValidation(event.target.value);
                }
            } else if ([prop] == 'cvv') {
                if ((event.target.value.length <= 3 && this.checkNumbersOnly(event.target.value)) || event.target.value === '') {
                    this.cvvValidation(event.target.value);
                }
            }
        }
    };

    formatCardNumber = (cardNumber) => {
        var cleaned = ('' + cardNumber).replace(/\D/g, '')
        if (cleaned.length > 4) {
            cleaned = cleaned.substring(0, 4) + " " + cleaned.substring(4, cleaned.length)
        }
        if (cleaned.length > 9) {
            cleaned = cleaned.substring(0, 9) + " " + cleaned.substring(9, cleaned.length)
        }
        if (cleaned.length > 14) {
            cleaned = cleaned.substring(0, 14) + " " + cleaned.substring(14, cleaned.length)
        }
        if (cleaned.length > 19) {
            cleaned = cleaned.substring(0, 19)
        }
        return cleaned
    }

    checkNumbersOnly = (value) => {
        const re = /^[0-9\b]+$/;
        var isValid = re.test(parseInt(value));
        return isValid;
    }


    toggle = () => {
        if (this.state.modal) {
            this.clearStates();
            
            
        }
        if (this.state.modal === true) {
            this.props.onClose();
        }
        this.setState({ modal: !this.state.modal });
    }

    clearStates = () => {
        this.setState({
            cardNumber: "",
            cardHolderName: "",
            expiryMonth: "",
            expiryYear: "",
            cvv: "",
            cardNumberError: false,
            cardHolderNameError: false,
            expiryMonthError: false,
            expiryYearError: false,
            cvvError: false
        });
    }

    handleSubmit = () => {
        if (this.validateFormData()) {
            this.setState({ showLoader: true });
            let params = {
                number: this.state.cardNumber.replace(/\s/g, ''), //remove all added white spaces
                cvc: this.state.cvv,
                exp_month: +this.state.expiryMonth,
                exp_year: +this.state.expiryYear,
                name: this.state.cardHolderName
            }

            saveCardDetails(params, (status, response) => {
                console.log('--------------------1122----------res-', response)
                if (status === 200) {
                    let payload = {
                        stripeToken: response.id
                    }
                    addNewCardAPI(payload).then(apiResponse => {
                        this.setState({ showLoader: false });
                        this.toggle();
                        //this.props.navigation.pop();

                    }).catch(this.handleError);
                } else {
                    this.handleError(response.error);
                }
            })
        }
    }

    handleError = (error) => {
        this.setState({ showLoader: false }, () => {
            if (error?.data?.message) {
                showToast(error?.data?.message, "error");
            } else if (error.message) {
                showToast(error.message, "error");
            }
        });
    }

    errorView = (msg) => {
        return (
            <p className="error-msg-style">{msg}</p>
        )
    }

    render() {
        const { classes } = this.props;
        console.log("AddCardProps",this.props)
        return (
            <div>
                {showSpinner(this.state.showLoader)}
                <Modal isOpen={this.state.modal} toggle={this.toggle} centered backdrop='static' className="change-pwd-modal" >
                    <ModalBody>
                        <div className="modal-custom-header">
                            <div className="heading-text">{strings.add_new_card}</div>
                            <div className="close-icon" onClick={this.toggle}>
                                <img src={closeIcon} alt="close-icon" />
                            </div>
                        </div>
                        <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
                            <InputLabel className={this.state.cardHolderNameError ? "change-pwd-input-label error-msg-color" : "change-pwd-input-label"} htmlFor="name" >{strings.name_on_card}</InputLabel>
                            <OutlinedInput
                                id="name"
                                type='text'
                                value={this.state.cardHolderName}
                                size="small"
                                onChange={this.handleChange('cardHolderName')}
                                className="change-pwd-input-top change-pwd-input-val"
                                error={this.state.cardHolderNameError}
                                labelWidth={70}
                            />
                            {this.state.cardHolderNameError && this.cardHolderNameError()}
                        </FormControl>

                        <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
                            <InputLabel className={this.state.cardNumberError ? "change-pwd-input-label error-msg-color" : "change-pwd-input-label"} htmlFor="card-no">{strings.card_number}</InputLabel>
                            <OutlinedInput
                                id="card-no"
                                type='text'
                                value={this.state.cardNumber}
                                size="small"
                                onChange={this.handleChange('cardNumber')}
                                className="change-pwd-input-top change-pwd-input-val"
                                error={this.state.cardNumberError}
                                labelWidth={70}
                            />
                            {this.state.cardNumberError && this.cardNumberError()}
                        </FormControl>

                        <Row md={3} className="date-row">
                            <Col>
                                <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
                                    <MediaQuery minWidth={480}>
                                        {/* <InputLabel className={this.state.expiryMonthError ? "change-pwd-input-label error-msg-color" : "change-pwd-input-label"} htmlFor="expiry-month">{strings.expiry_month}</InputLabel> */}
                                         <InputLabel className={this.state.expiryMonthError ? "change-pwd-input-label error-msg-color" : "change-pwd-input-label"}>{strings.expiry_month}</InputLabel>
                                    </MediaQuery>
                                    <MediaQuery maxWidth={480}>
                                        <InputLabel className={this.state.expiryMonthError ? "change-pwd-input-label error-msg-color" : "change-pwd-input-label"} >Exp.Month</InputLabel>
                                    </MediaQuery>
                                    <OutlinedInput
                                        id="expiry-month"
                                        type='text'
                                        value={this.state.expiryMonth}
                                        size="small"
                                        onChange={this.handleChange('expiryMonth')}
                                        className="change-pwd-input-top change-pwd-input-val"
                                        error={this.state.expiryMonthError}
                                        labelWidth={70}
                                    />
                                    {this.state.expiryMonthError && this.expiryMonthError()}
                                </FormControl>
                            </Col>
                            <Col>
                                <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
                                    <MediaQuery minWidth={480}>
                                    <InputLabel className={this.state.expiryYearError ? "change-pwd-input-label error-msg-color" : "change-pwd-input-label"} htmlFor="expiryYear">{strings.expiry_year}</InputLabel>
                                    </MediaQuery>
                                    <MediaQuery maxWidth={480}>
                                    <InputLabel className={this.state.expiryYearError ? "change-pwd-input-label error-msg-color" : "change-pwd-input-label"} htmlFor="expiryYear">Exp. Year</InputLabel>
                                    </MediaQuery>
                                    <OutlinedInput
                                        id="expiryYear"
                                        type='text'
                                        value={this.state.expiryYear}
                                        size="small"
                                        onChange={this.handleChange('expiryYear')}
                                        className="change-pwd-input-top change-pwd-input-val"
                                        error={this.state.expiryYearError}
                                        labelWidth={70}
                                    />
                                    {this.state.expiryYearError && this.expiryYearError()}
                                </FormControl>
                            </Col>
                            <Col>
                                <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
                                    <InputLabel className={this.state.cvvError ? "change-pwd-input-label error-msg-color" : "change-pwd-input-label"} htmlFor="cvv">{strings.cvv}</InputLabel>
                                    <OutlinedInput
                                        id="cvv"
                                        type='text'
                                        value={this.state.cvv}
                                        size="small"
                                        onChange={this.handleChange('cvv')}
                                        className="change-pwd-input-top change-pwd-input-val"
                                        error={this.state.cvvError}
                                        labelWidth={70}
                                    />
                                    {this.state.cvvError && this.cvvError()}
                                </FormControl>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="negative-btn" onClick={this.toggle}>{strings.cancel}</Button>{' '}
                        <Button className="positive-btn" onClick={this.handleSubmit}>{strings.submit}</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(AddCard)