import React from 'react';
import SubscriptionComponent from '../components/subscription';

const Subscription = props => {
    return (
        <div>
            <SubscriptionComponent />
        </div>
    );
}
export default Subscription;