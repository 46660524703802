
export const APP_NAME = 'LuxeCrews';
export const APP_VERSION = '0.0.1';

export const firebaseConfig = {
    apiKey: "AIzaSyDIvdXzxxeChJIhIWInJTRjlligkuoUyK8",
    authDomain: "luxecrews-4b55c.firebaseapp.com",
    projectId: "luxecrews-4b55c",
    storageBucket: "luxecrews-4b55c.appspot.com",
    messagingSenderId: "11184207443",
    appId: "1:11184207443:web:ef26751a38e698a32ef8a6",
    measurementId: "G-Y1GLCJL5GJ"
  };

  export const terms_privacy_base_url = "http://beta2.luxecrews.com/";