import React, { Component } from "react";
import LocalizedStrings from "react-localization";

import AmericanExpressIcon from "../../../assets/images/cards/ic_american_express.svg";
import BankOfAmericaIcon from "../../../assets/images/cards/ic_bank_of_america.svg";
import BarclaysIcon from "../../../assets/images/cards/ic_barclays.svg";
import CapitalOneBankIcon from "../../../assets/images/cards/ic_capital_one_bank.svg";
import RadioOff from "../../../assets/radio_off.png";
import RadioOn from "../../../assets/radio_on.png";
import ChaseIcon from "../../../assets/images/cards/ic_chase.svg";
import CitiBankIcon from "../../../assets/images/cards/ic_citi_bank.svg";
import DefaultCardIcon from "../../../assets/images/cards/ic_default_card.svg";
import DinersClubIcon from "../../../assets/images/cards/ic_diner_club.svg";
import DiscoverIcon from "../../../assets/images/cards/ic_discover.svg";
import JcbIcon from "../../../assets/images/cards/ic_jcb.svg";
import MasterCardIcon from "../../../assets/images/cards/ic_master_card.svg";
import PncIcon from "../../../assets/images/cards/ic_pnc.svg";
import UsaaIcon from "../../../assets/images/cards/ic_usaa.svg";
import UsBankIcon from "../../../assets/images/cards/ic_us_bank.svg";
import VisaIcon from "../../../assets/images/cards/ic_visa.svg";
import WellsFargoIcon from "../../../assets/images/cards/ic_wells_fargo.svg";
import { data } from "../../../utils/locales/data";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from "reactstrap";
import { addNewCardAPI, CheckForDowngradeAPI, getAllCardListAPI, MakePaymentAPI } from "../../../utils/api-helper";
import { showToast, showSpinner } from "../../../utils/common_helper";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import "./style.scss";
import closeIcon from "../../../assets/images/modal_cross.svg";
import {
  saveCardDetails,
  initializeStripe,
} from "../../../utils/stripe-helper";
import { red } from "@material-ui/core/colors";
import MediaQuery from 'react-responsive'
import { act } from "react-dom/test-utils";

let strings = new LocalizedStrings(data);

const styles = (theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "100%",
    margin: "8px 0px 23px",
  },
});

class AddCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cardNumber: "",
      cardHolderName: "",
      expiryMonth: "",
      expiryYear: "",
      cvv: "",
      cardNumberError: false,
      cardHolderNameError: false,
      expiryMonthError: false,
      expiryYearError: false,
      cvvError: false,
      isSubmitted: false,
      isAddNewCard: false,
      cardId: '',
      selectedIndex: 0,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.modal !== nextProps.modalStatus) {
      return {
        modal: nextProps.modalStatus,
      };
    }
  }

  componentDidMount = () => {
    let { cardList } = this.props
    cardList?.map((card, i) => {
      if (i == 0) {
        this.setState({ cardId: card.cardId });
      }
    })
    initializeStripe();
  };

  cardNameHolderValidation = (value) => {
    if (value) {
      this.setState({
        cardHolderNameError:
          value.trim() && value.trim().length > 0 ? false : true,
      });
    } else {
      this.setState({
        cardHolderNameError:
          this.state.cardHolderName.trim() &&
            this.state.cardHolderName.trim().length > 0
            ? false
            : true,
      });
    }
  };
  handlePay = () => {
    let { data ,activate_auto_renewal,transaction_id } = this.props;

    this.setState({ showLoader: true });
    let body = {
      cardId: this.state.cardId,
      amount: data.price,
      duration: data.duration,
      subscriptionID: data.id,
      planType: data.planType,
      autoRenewal: data?.isAutoRenewal ? true : false,
    };

  if(activate_auto_renewal)
  {
    this.handleRenewal(transaction_id,this.state.cardId);
  }else{

    CheckForDowngradeAPI(data.id,).then( res => {
      if(res?.code == 200){
        MakePaymentAPI(body).then(res => {
          this.setState({ showLoader: false });
          this.props.onConfirm(data.price);
    
    
        }).catch(this.handleError);
      }
    }).catch(this.handleError);
  }
 
    // this.props.onClose();
  }


  handleCardeSelection = (cardId, i) => {
    this.setState({ selectedIndex: i,
      cardNumber: "",
      cardHolderName: "",
      expiryMonth: "",
      expiryYear: "",
      cvv: "",
    });
    if (cardId === 'New_Card') {
      this.setState({ isAddNewCard: !this.state.isAddNewCard, cardId: '', });
    } else {
      this.setState({ isAddNewCard: false, cardId: cardId });

    }

  };

  expiryMonthValidation = (value) => {
    if (value) {
      this.setState({
        expiryMonthError:
          value.trim() && value.trim().length >= 2 ? false : true,
      });
    } else {
      this.setState({
        expiryMonthError:
          this.state.expiryMonth.trim() &&
            this.state.expiryMonth.trim().length >= 2
            ? false
            : true,
      });
    }
  };

  expiryYearValidation = (value) => {
    if (value) {
      this.setState({
        expiryYearError:
          value.trim() && value.trim().length >= 2 ? false : true,
      });
    } else {
      this.setState({
        expiryYearError:
          this.state.expiryYear.trim() &&
            this.state.expiryYear.trim().length >= 2
            ? false
            : true,
      });
    }
  };

  cvvValidation = (value) => {
    if (value) {
      this.setState({
        cvvError:
          value && value.trim() == ""
            ? false
            : value.trim().length >= 3
              ? false
              : true,
      });
    } else {
      this.setState({
        cvvError:
          this.state.cvv && this.state.cvv.trim() == ""
            ? false
            : this.state.cvv.trim().length >= 3
              ? false
              : true,
      });
    }
  };

  cardNumberValidation = (value) => {
    if (value) {
      this.setState({
        cardNumberError:
          value.trim() && value.trim().length >= 17 ? false : true,
      });
    } else {
      this.setState({
        cardNumberError:
          this.state.cardNumber.trim() &&
            this.state.cardNumber.trim().length >= 17
            ? false
            : true,
      });
    }
  };

  cardNumberError() {
    if (!this.state.cardNumber.trim()) {
      return this.errorView(strings.error_empty_card_number);
    } else if (
      this.state.cardNumber.trim().length >= 17 ||
      this.state.cardNumber.trim().length < 14
    ) {
      return this.errorView(strings.error_invalid_card_number);
    }
  }

  cardHolderNameError() {
    return this.errorView(strings.error_invalid_holder_name);
  }

  expiryMonthError() {
    return this.errorView(strings.error_empty_expiry_month);
  }

  expiryYearError() {
    return this.errorView(strings.error_empty_expiry_year);
  }

  cvvError() {
    if (!this.state.cvv.trim()) {
      return this.errorView(strings.error_empty_cvv);
    } else if (this.state.cvv.trim().length < 3) {
      return this.errorView(strings.error_invalid_cvv);
    }
  }

  handleSubmit = () => {
    this.setState({ isSubmitted: true });
    this.cardNameHolderValidation(this.state.cardHolderName);
    this.cardNumberValidation(this.state.cardNumber);
    this.expiryMonthValidation(this.state.expiryMonth);
    this.expiryYearValidation(this.state.expiryYear);
    this.cvvValidation(this.state.cvv);
    this.validateFormData();
  };

  checkFields = () => {
    let isError = false;
    if (
      this.state.cardNumber.trim() && this.state.cardNumber.trim().length >= 17
        ? false
        : true
    ) {
      isError = true;
    }

    if (
      this.state.expiryMonth.trim() &&
      this.state.expiryMonth.trim().length >= 2
    ) {
      isError = true;
    }

    if (
      this.state.expiryYear.trim() &&
      this.state.expiryYear.trim().length >= 2
    ) {
      isError = true;
    }

    if (
      this.state.cvv.trim() == "" ? false : this.state.cvv.trim().length >= 3
    ) {
      isError = true;
    }

    return !isError;
  };

  validateFormData = () => {
    this.state.cardNumberError = true;
    this.state.expiryMonthError = true;
    this.state.expiryYearError = true;
    this.state.cvvError = true;
    this.cardNumberValidation();
    this.expiryMonthValidation();
    this.expiryYearValidation();
    this.cvvValidation();
    if (
      (this.state.cardNumber.trim() && this.state.cardNumber.trim().length >= 17
        ? false
        : true) ||
      (this.state.expiryMonth.trim() &&
        this.state.expiryMonth.trim().length >= 1 &&
        +this.state.expiryMonth != 0
        ? false
        : true) ||
      (this.state.expiryYear.trim() && this.state.expiryYear.trim().length >= 2
        ? false
        : true) ||
      (this.state.cvv.trim().length >= 3 ? false : true)
    ) {
      return false;
    }
    return true;
  };

  handleChange = (prop) => (event) => {
    if ([prop] == "cardHolderName") {
      this.setState({ [prop]: event.target.value });
      // this.cardNameHolderValidation(event.target.value);
    } else if ([prop] == "cardNumber") {
      this.setState({ [prop]: this.formatCardNumber(event.target.value) });
      // this.cardNumberValidation(this.formatCardNumber(event.target.value));
    } else if ([prop] == "expiryMonth") {
      if (
        (event.target.value.length <= 2 &&
          this.checkNumbersOnly(event.target.value)) ||
        event.target.value === ""
      ) {
        this.setState({ [prop]: event.target.value });
        // this.expiryMonthValidation(event.target.value);
      }
    } else if ([prop] == "expiryYear") {
      if (
        (event.target.value.length <= 2 &&
          this.checkNumbersOnly(event.target.value)) ||
        event.target.value === ""
      ) {
        this.setState({ [prop]: event.target.value });
        // this.expiryYearValidation(event.target.value);
      }
    } else if ([prop] == "cvv") {
      if (
        (event.target.value.length <= 3 &&
          this.checkNumbersOnly(event.target.value)) ||
        event.target.value === ""
      ) {
        this.setState({ [prop]: event.target.value });
        // this.cvvValidation(event.target.value);
      }
    }

    if (this.state.isSubmitted) {
      if ([prop] == "cardHolderName") {
        this.cardNameHolderValidation(event.target.value);
      } else if ([prop] == "cardNumber") {
        this.cardNumberValidation(this.formatCardNumber(event.target.value));
      } else if ([prop] == "expiryMonth") {
        if (
          (event.target.value.length <= 2 &&
            this.checkNumbersOnly(event.target.value)) ||
          event.target.value === ""
        ) {
          this.expiryMonthValidation(event.target.value);
        }
      } else if ([prop] == "expiryYear") {
        if (
          (event.target.value.length <= 2 &&
            this.checkNumbersOnly(event.target.value)) ||
          event.target.value === ""
        ) {
          this.expiryYearValidation(event.target.value);
        }
      } else if ([prop] == "cvv") {
        if (
          (event.target.value.length <= 3 &&
            this.checkNumbersOnly(event.target.value)) ||
          event.target.value === ""
        ) {
          this.cvvValidation(event.target.value);
        }
      }
    }
  };

  formatCardNumber = (cardNumber) => {
    var cleaned = ("" + cardNumber).replace(/\D/g, "");
    if (cleaned.length > 4) {
      cleaned =
        cleaned.substring(0, 4) + " " + cleaned.substring(4, cleaned.length);
    }
    if (cleaned.length > 9) {
      cleaned =
        cleaned.substring(0, 9) + " " + cleaned.substring(9, cleaned.length);
    }
    if (cleaned.length > 14) {
      cleaned =
        cleaned.substring(0, 14) + " " + cleaned.substring(14, cleaned.length);
    }
    if (cleaned.length > 19) {
      cleaned = cleaned.substring(0, 19);
    }
    return cleaned;
  };

  checkNumbersOnly = (value) => {
    const re = /^[0-9\b]+$/;
    var isValid = re.test(parseInt(value));
    return isValid;
  };

  toggle = () => {
    if (this.state.modal) {
      this.clearStates();
    }
    if (this.state.modal === true) {
      this.props.onClose();
    }
    this.setState({ modal: !this.state.modal });
  };

  clearStates = () => {
    this.setState({
      cardNumber: "",
      cardHolderName: "",
      expiryMonth: "",
      expiryYear: "",
      cvv: "",
      cardNumberError: false,
      cardHolderNameError: false,
      expiryMonthError: false,
      expiryYearError: false,
      cvvError: false,
    });
  };

  getBrandImage = (brandType) => {
    if (brandType) {
      if (brandType.toLowerCase().search("visa") > -1) {
        return VisaIcon;
      } else if (brandType.toLowerCase().search("mastercard") > -1) {
        return MasterCardIcon;
      } else if (brandType.toLowerCase().search("american express") > -1) {
        return AmericanExpressIcon;
      } else if (brandType.toLowerCase().search("discover") > -1) {
        return DiscoverIcon;
      } else if (brandType.toLowerCase().search("diners club") > -1) {
        return DinersClubIcon;
      } else if (brandType.toLowerCase().search("jcb") > -1) {
        return JcbIcon;
      } else if (brandType.toLowerCase().search("barclays") > -1) {
        return BarclaysIcon;
      } else if (brandType.toLowerCase().search("chase") > -1) {
        return ChaseIcon;
      } else if (brandType.toLowerCase().search("bank of america") > -1) {
        return BankOfAmericaIcon;
      } else if (brandType.toLowerCase().search("usaa") > -1) {
        return UsaaIcon;
      } else if (brandType.toLowerCase().search("usbank") > -1) {
        return UsBankIcon;
      } else if (brandType.toLowerCase().search("pnc") > -1) {
        return PncIcon;
      } else if (brandType.toLowerCase().search("capital one bank") > -1) {
        return CapitalOneBankIcon;
      } else if (brandType.toLowerCase().search("citi bank") > -1) {
        return CitiBankIcon;
      } else if (brandType.toLowerCase().search("wells fargo") > -1) {
        return WellsFargoIcon;
      } else {
        return DefaultCardIcon;
      }
    }
  };

handleRenewal = (transaction_id,card_id) => {
  console.log('-----------------------------------renewal-----')

  console.log('-------------------transaction id',transaction_id)
  this.props.onConfirm(transaction_id,card_id);
}
  handleSubmit = () => {
    
    if (this.validateFormData()) {
      this.setState({ showLoader: true });
      let params = {
        number: this.state.cardNumber.replace(/\s/g, ""), //remove all added white spaces
        cvc: this.state.cvv,
        exp_month: +this.state.expiryMonth,
        exp_year: +this.state.expiryYear,
        name: this.state.cardHolderName,
      };

      saveCardDetails(params, (status, response) => {
        console.log('------------------------------strip---', response)
        console.log('------------------------------strip---', status)

        if (status === 200) {
          let payload = {
            stripeToken: response.id,
          };
          addNewCardAPI(payload)
            .then((apiResponse) => {
              console.log('---------------------------add crd----', apiResponse.data)
              this.setState({ showLoader: false, cardId: apiResponse?.data?.cardId });
              this.handlePay();

              //   this.toggle();
              //this.props.navigation.pop();
            })
            .catch(this.handleError);
        } else {
          console.log('------------------------------else----error')
          this.handleError(response.error);
        }
      });
    }
  };

  setAsPrimaryCard = (item, index) => {
    var updatedCardList = this.state.cardList;
    let newList = updatedCardList.map((item, idx) => {
      item.isDefault = index === idx;
      return item;
    });
    this.setState({ cardList: newList });
  };

  handleError = (error) => {
    console.log('-----------------------------------------handle---error--', error)
    this.setState({ showLoader: false }, () => {
      if (error?.data?.message) {
        showToast(error?.data?.message, "error");
      } else if (error.message) {
        showToast(error.message, "error");
      }
    });
  };

  errorView = (msg) => {
    return <p className="error-msg-style">{msg}</p>;
  };

  render() {
    const { classes, data, activate_auto_renewal, cardList, button_text, modelTitle } = this.props;
    let months = data?.duration;
    return (
      <div>
        {showSpinner(this.state.showLoader)}
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          centered
          backdrop="static"
          className="change-pwd-modal"
        >
          <ModalBody>
            <div className="modal-custom-header">
              <div className="heading-text">{modelTitle}</div>
              <div className="close-icon" onClick={this.toggle}>
                <img src={closeIcon} alt="close-icon" />
              </div>
            </div>
            <div className="main-content">
              <div className="plan-detail">
                <div className="plan-name">{data.plan_name}</div>
                <div>
                  {" "}
                  <label className="plan-amount">{"$" + data.price}</label>{" "}

                  <label className="plan-duration">
                    {'  /  '} {months == 6 ? months : ''}{months == 6 ? '  months' : months == 12 ? '  year' : '  month'}``

                  </label>
                </div>
              </div>

              {activate_auto_renewal ? <div className="renewal-message-box">
                <div className="renewal-title">{strings.auto_renewal_title}</div>
                <div>
                  {" "}
                  <label className="renewal-message">{strings.auto_renewal_message}</label>{" "}

                </div>
              </div> : null}

              {cardList.length > 0 ? (
                <div className="existing-card-box">
                  <div className="add-card-heading">
                    {strings.existing_card}
                  </div>
                  <>
                    {cardList
                      ? cardList.map((card, index) => {

                        return (
                          <div>
                            <div></div>
                            <div className="card-content">
                              <input
                                type="radio"
                                id="radio1"
                                name="card"
                                onChange={() => { this.handleCardeSelection(card.cardId, index) }}
                                className="radio-control-box"
                                value={card.cardId}
                                // checked={}
                                // checked="checked"
                                checked={index == this.state.selectedIndex && true}
                              />
                              <span></span>

                              <img
                                src={this.getBrandImage(card.brand)}
                                className="brand-image"
                              />
                              <div className="card-info">
                                <div>
                                  <p className="card-number">{`XXXX XXXX XXXX ${card.last4}`}</p>
                                  <p className="card-expiry">
                                    {strings.expires_labels}{" "}
                                    {`${card.expMonth}/${card.expYear}`}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                      : null}
                  </>
                </div>
              ) : null}
              <div className="add-card-heading-box">
                {cardList.length > 0 ? <>
                  <input
                    type="radio"
                    name="card"
                    id="radio1"
                    onChange={() => { this.handleCardeSelection("New_Card", cardList.length) }}
                    className="radio-control-box"
                    //   checked=""
                    // checked={}
                    value={"New_Card"}
                  />
                  <span></span>
                </> : null}
                <label style={cardList.length === 0 ? { marginLeft: '-5px', } : null} className="add-card-heading">
                  {strings.add_new_card}
                </label>
              </div>
            </div>
            {this.state.selectedIndex == cardList.length || cardList.length == 0 ? <>
              <FormControl
                className={clsx(classes.margin, classes.textField)}
                variant="outlined"
              >
                <InputLabel
                  className={
                    this.state.cardHolderNameError
                      ? "change-pwd-input-label error-msg-color"
                      : "change-pwd-input-label"
                  }
                  htmlFor="name"
                >
                  {strings.name_on_card}
                </InputLabel>
                <OutlinedInput
                  id="name"
                  type="text"
                  value={this.state.cardHolderName}
                  size="small"
                  onChange={this.handleChange("cardHolderName")}
                  className="change-pwd-input-top change-pwd-input-val"
                  error={this.state.cardHolderNameError}
                  labelWidth={70}
                />
                {this.state.cardHolderNameError && this.cardHolderNameError()}
              </FormControl>

              <FormControl
                className={clsx(classes.margin, classes.textField)}
                variant="outlined"
              >
                <InputLabel
                  className={
                    this.state.cardNumberError
                      ? "change-pwd-input-label error-msg-color"
                      : "change-pwd-input-label"
                  }
                  htmlFor="card-no"
                >
                  {strings.card_number}
                </InputLabel>
                <OutlinedInput
                  id="card-no"
                  type="text"
                  value={this.state.cardNumber}
                  size="small"
                  onChange={this.handleChange("cardNumber")}
                  className="change-pwd-input-top change-pwd-input-val"
                  error={this.state.cardNumberError}
                  labelWidth={70}
                />
                {this.state.cardNumberError && this.cardNumberError()}
              </FormControl>

              <Row md={3} className="date-row">
                <Col>
                  <FormControl
                    className={clsx(classes.margin, classes.textField)}
                    variant="outlined"
                  >
                    <MediaQuery minWidth={480}>
                      <InputLabel className={this.state.expiryMonthError ? "change-pwd-input-label error-msg-color" : "change-pwd-input-label"} htmlFor="expiry-month">{strings.expiry_month}</InputLabel>
                    </MediaQuery>
                    <MediaQuery maxWidth={480}>
                      <InputLabel className={this.state.expiryMonthError ? "change-pwd-input-label error-msg-color" : "change-pwd-input-label"} htmlFor="expiry-month">Exp.Month</InputLabel>
                    </MediaQuery>
                    {/* <InputLabel className={this.state.expiryMonthError ? "change-pwd-input-label error-msg-color" : "change-pwd-input-label"} htmlFor="expiry-month">{strings.expiry_month}</InputLabel> */}
                                    <OutlinedInput
                                        id="expiry-month"
                                        type='text'
                                        value={this.state.expiryMonth}
                                        size="small"
                                        onChange={this.handleChange('expiryMonth')}
                                        className="change-pwd-input-top change-pwd-input-val"
                                        error={this.state.expiryMonthError}
                                        labelWidth={70}
                                    />
                    {this.state.expiryMonthError && this.expiryMonthError()}
                  </FormControl>
                </Col>
                <Col>
                  <FormControl
                    className={clsx(classes.margin, classes.textField)}
                    variant="outlined"
                  >
                    <MediaQuery minWidth={480}>
                    <InputLabel className={this.state.expiryYearError ? "change-pwd-input-label error-msg-color": "change-pwd-input-label" }htmlFor="expiryYear">{strings.expiry_year}</InputLabel>
                    </MediaQuery>
                    <MediaQuery maxWidth={480}>
                    <InputLabel className={this.state.expiryYearError ? "change-pwd-input-label error-msg-color": "change-pwd-input-label" }htmlFor="expiryYear">Exp. Year</InputLabel>
                    </MediaQuery>
                    <OutlinedInput
                      id="expiryYear"
                      type="text"
                      value={this.state.expiryYear}
                      size="small"
                      onChange={this.handleChange("expiryYear")}
                      className="change-pwd-input-top change-pwd-input-val"
                      error={this.state.expiryYearError}
                      labelWidth={70}
                    />
                    {this.state.expiryYearError && this.expiryYearError()}
                  </FormControl>
                </Col>
                <Col>
                  <FormControl
                    className={clsx(classes.margin, classes.textField)}
                    variant="outlined"
                  >
                    <InputLabel
                      className={
                        this.state.cvvError
                          ? "change-pwd-input-label error-msg-color"
                          : "change-pwd-input-label"
                      }
                      htmlFor="cvv"
                    >
                      {strings.cvv}
                    </InputLabel>
                    <OutlinedInput
                      id="cvv"
                      type="text"
                      value={this.state.cvv}
                      size="small"
                      onChange={this.handleChange("cvv")}
                      className="change-pwd-input-top change-pwd-input-val"
                      error={this.state.cvvError}
                      labelWidth={70}
                    />
                    {this.state.cvvError && this.cvvError()}
                  </FormControl>
                </Col>
              </Row>
            </> : null}
          </ModalBody>
          <ModalFooter>
            
            <Button
              className="positive-btn"
              onClick={
                this.state.isAddNewCard
                  ? this.handleSubmit
                  : cardList.length > 0 ? this.handlePay
                    : this.handleSubmit
              }
            >
              {button_text}
            </Button>

          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(AddCard);
