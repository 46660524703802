import _ from "lodash";
import React, { Component } from "react";
import LocalizedStrings from "react-localization";
import { connect } from "react-redux";
import { Col, Row, Button } from "reactstrap";
import Polygon from "../../assets/polygon.png";
import CrossIcon from "../../assets/cross_icon.png";

import UnsubscribeIcon from "../../assets/unsubscribe.png";
import SubscribeIcon from "../../assets/subscribe.png";


import DotIcon from "../../assets/dot.png";
import InfoIcon from "../../assets/info.png";
import RadioOn from '../../assets/radio_on.png'
import RadioOff from '../../assets/radio_off.png';
import AddCard from './add_card';
import MediaQuery from 'react-responsive'


import {
  confirmAlert,
  showSpinner,
  showToast,
} from "../../utils/common_helper";
import { getAllCardListAPI, manageAutoRenewal, unsubscribeAPI, getActivePlanAPI, getAllSubscriptionList } from "../../utils/api-helper";

import { data } from "../../utils/locales/data";

import ConfirmModel from "./confirm_model";
import "./style.scss";
import { NatureOutlined } from "@material-ui/icons";

let strings = new LocalizedStrings(data);
class Subscription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowModelForUnsubscribe: false,
      selectedPlan: [],
      cardList: [],
      hasCards: false,
      activePlanList: [],
      planList: [],

      subscribeConfirmation: false,
      showAlertPopup: false,
      activePlanSelected: {},
      isLoading: false,
      showLoader: false,
      isSubscriptionSelected: false,
      infoPopup: false,
      isShowModelForRenewal: false,
      isRadioOn: false,
      showCardPopup: false,
      showActivateAutoRenewalPopup: false,
      showPaymentSuccess: false,
      planAmount: 0,
      activatePlan: {},
      stringForRenewal: '',
    };
  }
  componentDidMount() {
    this.getActivePlan();
    this.getAllsubscriptions();
    this.getAllCardsList();
  }
  getActivePlan = () => {
    getActivePlanAPI().then(res => {
      // console.log('------active plan response--------------', res.data)
      this.setState({ activePlanList: res.data });
    }).catch(this.handleError);
  }

  getAllsubscriptions = () => {
    this.setState({ isLoading: true });

    getAllSubscriptionList()
      .then((res) => {
        // console.log("-------------------------------ase---", res.data);
        this.setState({ planList: res.data, isLoading: false });
      })
      .catch(this.handleError);
  };

  planUnsubscribe = (body) => {
    this.setState({ isLoading: true });
    unsubscribeAPI(body).then(res => {
      this.clearState();
      this.getAllCardsList();
      this.getActivePlan();
      this.getAllsubscriptions();


    }).catch(this.handleError);
  }

  getAllCardsList = () => {
    // this.setState({ isLoading: true });
    getAllCardListAPI().then(response => {
      // this.setState({ isLoading: false,    })
      if (response?.data) {
        var updatedCardList = response.data ? response.data : '';
        this.setState({ cardList: updatedCardList, hasCards: true })
      }

    }).catch(this.handleError);
  }
  onCloseModal = () => {
    this.setState({ isShowModelForUnsubscribe: false });
  };

  onCloseModalForCancelRenewal = () => {
    this.setState({ isShowModelForRenewal: false });
  };

  handleClick = () => {
    this.setState({ isShowModelForUnsubscribe: true });
  };
  handleClickOnCancelRenewal = () => {
    if (this.state.activePlanSelected?.autoRenewal) {
      this.setState({ isShowModelForRenewal: true });

    } else {
      let tempData = this.state.activePlanSelected;
      let tempArray = {
        id: tempData.id,
        plan_name: tempData.planName,
        price: tempData.amount,
        duration: tempData.duration,
        // plan_details: tempData.planDetailText,
        // isEligibleForUnlimitedPost: planData.isEligibleForUnlimitedPost,
        // numberOfAircrafts: planData.numberOfAircrafts,
        // numberOfAdminAndManagers: planData.numberOfAdminAndManagers,
        // numberOfPilot: planData.numberOfPilot,
        // numberOfAttendants: planData.numberOfAttendants,
        // planType: planData.planType,
      }
      this.setState({ showActivateAutoRenewalPopup: true, activatePlan: tempArray });

    }

  };

  handleError = (error) => {
    console.log('-------------------------------handle---error---1212----', error)
    this.setState(
      { showLoader: false, isLoading: false, hasCards: false, isDataFatched: true },
      () => {
        if (error?.message) {
          showToast(error?.message, "error");
        }
      }
    );
  };
  onCloseAddCardPopup = () => {
    this.setState({ showCardPopup: false });

    this.getAllsubscriptions();
    this.getAllCardsList();
  }
  onCloseActivateAutoRenewalPopup = () => {
    this.setState({ showActivateAutoRenewalPopup: false });
    this.getAllsubscriptions();
    this.getAllCardsList();


  }
  onConfirmUnsubscription = (value) => {
    console.log("---------------------------------------------val", value);
    let transaction_id = value;
    this.planUnsubscribe(transaction_id)
    this.setState({ isShowModelForUnsubscribe: false })

  };
  onConfirmRenewal = (id) => {
    this.setState({ isShowModelForRenewal: false, showActivateAutoRenewalPopup: false, isLoading: true })
    manageAutoRenewal(id).then(res => {
      console.log('----------------------------cancel----', res)
      this.clearState();
      this.getActivePlan();
      this.getAllCardsList();
      this.getAllsubscriptions();

    }).catch(this.handleError);
  };

  onConfirmActivateRenewal = (transactionId, cardId) => {
    console.log('--------------------------------------------tran--card----', transactionId, cardId)
    this.setState({ showActivateAutoRenewalPopup: false, isLoading: true })
    // console('-----------------------------activate----calll')
    manageAutoRenewal(transactionId, cardId).then(res => {
      console.log('----------------------------cancel----', res)
      this.clearState();
      this.getActivePlan();
      this.getAllCardsList();
      this.getAllsubscriptions();

    }).catch(this.handleError);
  };

  hideInfoModel = () => {
    this.setState({ infoPopup: false });
  };
  showInfoModel = () => {
    this.setState({ infoPopup: true });
  };
  handleOnActivePlanSelected = (data) => {
    // console.log('-------------------seleted---item', data)
    let temp = '';
    if (data.autoRenewal) {
      temp = strings.cancel_auto_renewal;
    } else {
      temp = strings.activate_auto_renewal;
    }
    this.setState({ activePlanSelected: data, stringForRenewal: temp });
  };
  onConfirmSubscription = (data) => {
    this.setState({ subscribeConfirmation: false, showCardPopup: true, selectedPlan: data }, () => {
    });
  };
  closeAlertPopup = () => {
    this.setState({ showAlertPopup: false });
  };

  onPaymentDone = (data) => {
    this.clearState()
    this.getActivePlan()
    this.getAllCardsList()
    this.getAllsubscriptions()
    this.setState({ showCardPopup: false, showPaymentSuccess: true, planAmount: data });
  }
  clearState = () => {
    this.setState({
      isShowModelForUnsubscribe: false,
      selectedPlan: [],
      cardList: [],
      hasCards: false,
      activePlanList: [],
      planList: [],

      subscribeConfirmation: false,
      showAlertPopup: false,
      activePlanSelected: {},
      isLoading: false,
      showLoader: false,
      isSubscriptionSelected: false,
      infoPopup: false,
      isShowModelForRenewal: false,
      isRadioOn: false,
      showCardPopup: false,
      showActivateAutoRenewalPopup: false,
      showPaymentSuccess: false,
      planAmount: 0,
      activatePlan: {},
      stringForRenewal: '',
    });
  }

  renderPriceList(planData, i, months) {
    if(i.planAmount == 0){
      return;
    }
    return (
      <div className="priceList2">
        {months == this.state.selectedPlan.duration && planData.id == this.state.selectedPlan.id ? <img
          src={RadioOn}

          name="price-option"
          className="radio-btn"
          onClick={() => {

            this.setState({ isSubscriptionSelected: false, selectedPlan: [] });
          }}
        /> :
          <img src={RadioOff}
            onClick={() => {
              let tempArray = {
                id: planData.id,
                plan_name: planData.planName,
                price: i.planAmount,
                duration: months,
                plan_details: planData.planDetailsText,
                isEligibleForUnlimitedPost: planData.isEligibleForUnlimitedPost,
                numberOfAircrafts: planData.numberOfAircrafts,
                numberOfAdminAndManagers: planData.numberOfAdminAndManagers,
                numberOfPilot: planData.numberOfPilot,
                numberOfAttendants: planData.numberOfAttendants,
                planType: planData.planType,
              }
              this.setState({ isSubscriptionSelected: true, selectedPlan: tempArray });
            }}
            className="radio-btn"
          />
        }
        <label className="price">{"$" + i.planAmount}</label>
        <span className="type">{'  /  '} {months == 6 ? months : ''}<span>{months == 6 ? '  months' : months == 12 ? '  year' : '  month'}</span></span>
      </div>
    );
  }

  renderItem(item) {

    if (item?.isActivate) {
      return (
        <div className="item-div">
          <div className="row1">
            <label for="plan-name" className="subscription-name">
              {item.planName}
            </label>
          </div>
          <div className="subscription-plan-detail">
            <div className="price-list">
              {item.planTypesDetails.map(i => {
                let temp = (i.planDuration / 30);
                let months = Math.trunc(temp);

                return this.renderPriceList(item, i, months);
              })}

            </div>
            <>
              <label className="benefits">{strings.benefits}</label>
              <div className="grid-container">
                {item?.planType === "Job Post" ? <label className="benefit-data">
                  <img src={DotIcon} />
                  <span className="grid-item">{strings.unlimited_job_posts}</span>
                </label> : null}
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                {item?.numberOfPilot ? <label className="benefit-data">
                    <img src={DotIcon} />
                    <span className="grid-item">{strings.no_of_pilot}  {'  '}<span className="benefit-counters"> {item.numberOfPilot} </span></span>
                  </label> : null}
                {item?.numberOfAircrafts ? <label className="benefit-data">
                  <img src={DotIcon} />
                  <span className="grid-item">{strings.no_of_aircrafts} {'  '} <span className="benefit-counters">{item.numberOfAircrafts} </span></span>
                </label> : null}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }} className="subscriptionPlanArragmentforMobile">
                  {item?.numberOfAttendants ? <label className="benefit-data">
                    <img src={DotIcon} />
                    <span className="grid-item">{strings.no_of_attendent} {'  '}<span className="benefit-counters"> {item.numberOfAttendants}</span> </span>
                  </label> : null}

                  {item?.numberOfAdminAndManagers ? <label className="benefit-data">
                    <img src={DotIcon} />
                    <span className="grid-item">{strings.no_of_general} {'  '} <span className="benefit-counters">{item.numberOfAdminAndManagers} </span></span>
                  </label> : null}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  

                </div>
              </div>


            </>
            {item?.planDetailsText ? 
            <div className="benefits1">
              <label className="benefits">{strings.plan_details}</label>
              <div className="description">
                <p className="benefits-data ">{item.planDetailsText}</p>
              </div>
            </div> : null}

          </div>

        </div>
      );
    }


  }
  renderActiveItem = (item) => {
    console.log('-------------------------------active--plan---item----', item)
    return (
      <div>
        <div className="row1">
          {this.state.activePlanSelected?.id === item.id ? <img
            src={RadioOn}
            style={{ cursor: 'pointer' }}
            className="radio-btn"
          />
            :
            <img src={RadioOff}
              style={{ cursor: 'pointer' }}
              className="radio-btn"
              onClick={() => { this.handleOnActivePlanSelected(item) }}

            />
          }
          <label for="plan-name" className="subscription-name">
            {item.planName}
          </label>

        </div>
        <div className="plan-detail">
          <div>
            <label className="price">{"$" + item.amount}</label>
            <span className="type">{"  /  "} {item.duration == 6 ? item.duration : ''}<span>{item.duration == 6 ? '  months' : item.duration == 12 ? '  year' : '  month'}</span></span>
          </div>
          <label className="benefits">{strings.benefits}</label>
          <div className="grid-container">
          {item?.planType === "Job Post" ? <label className="benefit-data">
                  <img src={DotIcon} />
                  <span className="grid-item">{strings.unlimited_job_posts}</span>
                </label> : null}
            <div>
              <div>
            {item?.noOfAircraft ? <label className="benefit-data">
              <img src={DotIcon} />
              <span className="grid-item">{strings.no_of_aircrafts} {'  '} <span className="benefit-counters">{item.noOfAircraft} </span></span>
            </label> : null}
            </div>
            <div>
            {item?.noOfPilot ? <label className="benefit-data">
                <img src={DotIcon} />
                <span className="grid-item">{strings.no_of_pilot}  {'  '}<span className="benefit-counters"> {item.noOfPilot} </span></span>
              </label> : null}
              </div>
          </div>
            <div >
              <div>
            {item?.noOfGeneral ? <label className="benefit-data">
              <img src={DotIcon} />
              <span className="grid-item">{strings.no_of_general} {'  '} <span className="benefit-counters">{item.noOfGeneral} </span></span>
            </label> : null}
            </div>
            <div>
              {item?.noOfAttendent ? <label className="benefit-data">
                <img src={DotIcon} />
                <span className="grid-item">{strings.no_of_attendent} {'  '}<span className="benefit-counters"> {item.noOfAttendent}</span> </span>
              </label> : null}
              </div>
            </div>

          </div>
          <label className="benefits">{strings.plan_details}</label>
          <div className="description">
            <p className="benefits-data ">
              {item.planDetailText}
            </p>
            <label className="expiry_date">
              {strings.expiry_date + "  " + item.expiry_date.substring(0, 12)}
            </label>
          </div>
        </div>

      </div>
    )
  }

  renderActivePlan = () => {
    return (
      <>
        <div className="active-plan-box">
          <Row md={2} className="active-plan-header" style={{ backgroundColor: '#ebebeb' }}>
            {/* <Col className="title">{strings.active_plan}</Col> */}
            <p className="title">{strings.active_plan}</p>
            <Col className="header-icons">
              <div className="subscription-box">
                <Button
                  className="auto-renewal-btn "
                  onClick={this.state.activePlanSelected?.id ? this.handleClickOnCancelRenewal : null}
                >
                  {this.state.stringForRenewal ? this.state.stringForRenewal : strings.cancel_auto_renewal}
                </Button>
                {this.state.activePlanSelected?.id ? (
                  <Button
                    className="subscription-btn"
                    onClick={this.handleClick}
                  >
                    {strings.unsubscribe}
                  </Button>
                ) : (
                  <img id="unsubscribe_img_btn" src={UnsubscribeIcon} />

                )}
              </div>
              <div id="info-icon">
                <img src={InfoIcon} onClick={this.showInfoModel} />
              </div>
            </Col>
          </Row>

          {this.state.activePlanList?.map(el => {
            return this.renderActiveItem(el);
          })}
          <MediaQuery maxWidth={480}>
            <div className="subscription-box1">

              <Button
                className="auto-renewal-btn1"
                onClick={this.state.activePlanSelected?.id ? this.handleClickOnCancelRenewal : null}                >
                {/* {strings.activate_auto_renewal} */}
                {this.state.stringForRenewal ? this.state.stringForRenewal : strings.cancel_auto_renewal}

              </Button>
              {this.state.activePlanSelected?.id ? (
                <Button
                  className="subscription-btn1"
                  onClick={this.handleClick}
                >
                  {/* {strings.unsubscribe} */}
                  Unsubscribe
                </Button>
              ) : (
                <Button
                  className="subscription-btn1"

                >
                  {/* {strings.unsubscribe} */}
                  Unsubscribe
                </Button>

              )}
              <div id="info-icon1">
                <img style={{ height: 30, width: 30 }} src={InfoIcon} onClick={this.showInfoModel} />
              </div>
            </div>

          </MediaQuery>
        </div>
      </>
    );
  };
  renderSubscriptionPlans = () => {
    return (
      <div className="active-plan-box">
        <Row md={2} className="active-plan-header" style={{ backgroundColor: '#ebebeb' }}>
          {/* <Col className="title">{strings.subscription_plan}</Col> */}
          <p className="title">{strings.subscription_plan}</p>
          <Col className="header-icons">
            <div>
              {this.state.isSubscriptionSelected ? (
                <Button
                  className=" subscription-btn"
                  onClick={() => {
                    this.setState({ subscribeConfirmation: true });
                  }}
                >
                  {strings.subscribe}
                </Button>
              ) : (

                <img id="unsubscribe_img_btn" src={SubscribeIcon} onClick={() => {
                  showToast('Please select a plan to subscribe', "error");
                }} />
              )}
            </div>
          </Col>
        </Row>
        {/* {this.renderItem(this.state.planList)} */}

        {/* <RenderItem planlist={this.state.planList} /> */}
        {this.state.planList.map((item) => {
          // console.log(item);
          return this.renderItem(item);
        })}
        <MediaQuery maxWidth={480}>
          <div className="subscription-box1" style={{ alignSelf: "center", }}>
            {this.state.isSubscriptionSelected ? (
              <Button
                // className="subscription-btn1"
                className="subscription-btn2"
                onClick={() => this.setState({ subscribeConfirmation: true })}

              >
                Subscribe
              </Button>
            ) : (
              // <img id="unsubscribe_img_btn1" src={UnsubscribeIcon} style={{width:"100%",height:50}}/>
              <Button
                // style={{width:"auto"}}
                onClick={() => {
                  showToast('Please select a plan to subscribe', "error");
                }}
                className="subscription-btn2"
              >
                Subscribe
              </Button>

            )}
          </div>
        </MediaQuery>
      </div>
    );
  };

  render() {
    return (
      <>
        {/* {confirmAlert(
          strings.confirm_delete_card,
          "Yes",
          "Cancel",
          this.state.showConfirmAlert,
          "",
          () => {
            this.onConfirmDelete();
          },
          () => {
            this.onCancelDelete();
          }
        )} */}



        {showSpinner(this.state.isLoading)}
        {/* {!isBankDetailsView ? */}
        <>

          <div className="common-page-wrapper">
            <Row md={2} className="common-page-header" style={{ backgroundColor: '#ebebeb' }}>
              <Col className="title">{strings.subscription}</Col>
            </Row>
            <div className="page-content">
              {/* {isCardView ? */}
              {this.state.activePlanList.length > 0 ? this.renderActivePlan() : null}
              {this.renderSubscriptionPlans()}
            </div>
          </div>
          {this.state.infoPopup ? (
            <div className="info-model">
              <MediaQuery minWidth={480}>
                <div className="polygon">
                  <img className="polyImg" src={Polygon} />
                </div>
              </MediaQuery>

              <div className="info-box">
                <div className="cross-icon">
                  <img src={CrossIcon} onClick={this.hideInfoModel} />
                </div>
                <div className="content">
                  <ul className="list">
                    <li className="info-item">
                      {
                        "Unsubscribing from an active plan will stop sending data to your accounts for display. This is indicated as 'no data' entries, however, your data is still saved with LuxeCrews."
                      }
                    </li>
                    <li className="info-item">
                      {
                        "You will receive a prorated refund amount for the days remaining in the subscription plan. "
                      }
                    </li>
                    <li className="info-item">
                      {
                        "Please resubscribe at any time to access account information. "
                      }
                    </li>
                    <li className="info-item">
                      {'Please note that you can unsubscribe a plan only after 7 days of purchase.'}
                    </li>
                  </ul>

                </div>

              </div>
              <MediaQuery maxWidth={480}>
                <div className="polygon">
                  <img style={{
                    transform: `rotate(180deg)`,
                    transformOrigin: 'right top',
                    marginTop: 25,
                    marginLeft: 7
                  }} src={Polygon} />
                </div>
              </MediaQuery>

            </div>
          ) : null}
          {this.state.isShowModelForUnsubscribe ? (
            <ConfirmModel
              modalStatus={this.state.isShowModelForUnsubscribe}
              transaction_id={this.state.activePlanSelected?.tranactionsDetailsId}
              modelTitle={"Unsubscribe"}
              alertMessage={"Are you sure you wish to unsubscribe?"}
              onConfirm={(value) => {
                this.onConfirmUnsubscription(value);
              }}
              onClose={this.onCloseModal}
            ></ConfirmModel>
          ) : null}
          {this.state.isShowModelForRenewal ? (
            <ConfirmModel
              modalStatus={this.state.isShowModelForRenewal}
              modelTitle={strings.cancel_auto_renewal}
              transaction_id={this.state.activePlanSelected?.tranactionsDetailsId}
              alertMessage={"Are you sure you wish to cancel auto renewal?"}
              message={strings.cancel_auto_renewal_alert_message}
              onConfirm={(value) => {
                this.onConfirmRenewal(value);
              }}
              onClose={this.onCloseModalForCancelRenewal}
            ></ConfirmModel>
          ) : null}
          {this.state.subscribeConfirmation ? (
            <ConfirmModel
              modalStatus={this.state.subscribeConfirmation}
              modelTitle={strings.confirmation}

              data={this.state.selectedPlan}
              button_text={strings.proceed}
              alertMessage={this.state.selectedPlan.plan_name}
              message={strings.subscribe_confirm_message}
              onConfirm={(value) => {
                this.onConfirmSubscription(value);
              }}
              onClose={() => {
                this.setState({ subscribeConfirmation: false });
              }}
            ></ConfirmModel>
          ) : null}

          {this.state.showPaymentSuccess ? (
            <ConfirmModel
              modalStatus={this.state.showPaymentSuccess}
              // modelTitle={strings.confirmation}
              payment_success={true}
              planAmount={this.state.planAmount}
              title={strings.payment_success}
              button_text={strings.ok}
              alert={true}
              // alertMessage={this.state.selectedPlan.plan_name}
              // message={strings.payment_success}

              onClose={() => {
                this.setState({ showPaymentSuccess: false });
              }}
            ></ConfirmModel>
          ) : null}

          {this.state.showAlertPopup ? (
            <ConfirmModel
              modalStatus={this.state.showAlertPopup}
              modelTitle={strings.alert_title}
              alert={true}
              button_text={strings.ok}
              message={strings.subscribe_alert_message}

              onClose={this.closeAlertPopup}
            ></ConfirmModel>
          ) : null}
          {this.state.showCardPopup ? <AddCard
            modalStatus={this.state.showCardPopup}
            modelTitle={strings.payment}
            //  cardDetail={this.state.cardDetails}
            cardList={this.state.cardList}
            hasCards={this.state.hasCards}
            data={this.state.selectedPlan}
            button_text={strings.pay_now}
            alertMessage={this.state.selectedPlan.plan_name}
            //  message={strings.subscribe_confirm_message}
            onConfirm={(value) => {
              this.onPaymentDone(value);
            }}
            onClose={this.onCloseAddCardPopup}
          >

          </AddCard> : null}
          {this.state.showActivateAutoRenewalPopup ? <AddCard
            modalStatus={this.state.showActivateAutoRenewalPopup}
            modelTitle={strings.activate_auto_renewal}
            cardList={this.state.cardList}
            activate_auto_renewal={true}
            hasCards={this.state.hasCards}
            data={this.state.activatePlan}
            transaction_id={this.state.activePlanSelected?.tranactionsDetailsId}

            button_text={strings.activate}
            alertMessage={this.state.selectedPlan.plan_name}
            onConfirm={(transactionId, cardId) => {
              this.onConfirmActivateRenewal(transactionId, cardId);
            }}
            onClose={this.onCloseActivateAutoRenewalPopup}
          >

          </AddCard> : null}
        </>
        {/* : <AddBankDetails
                        bankDetails={this.state.bankDetails}
                        isAddBankDetails={this.state.isAddBankDetails}
                        onDeleteBank={() => this.callDeleteBankDetailsAPI()}
                        onAddBank={() => this.onAddBank()} />
                } */}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  authData: state.auth.authData,
});
export default connect(mapStateToProps, {})(Subscription);
